

















































import adminDataService from '@/services/admin-data-service'
import AdminState from '@/store/modules/admin'
import { StatementOfWorkTypeContract } from '@/types/dtos'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import EditableList from '@/components/EditableList.vue'
import LimitedTextDisplay from '@/components/util/LimitedTextDisplay.vue'

@Component({
  components: {
    EditableList,
    LimitedTextDisplay,
  }
})
export default class StatementOfWorkManagement extends Vue {

  adminState: AdminState = getModule(AdminState)
  sowTypes: StatementOfWorkTypeContract[] = []

  async mounted(): Promise<void> {
    await this.refreshDeps()
  }

  @Watch('adminState.activeProjectId')
  async onProjectChanged(): Promise<void> {
    await this.refreshDeps()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onAddNew(event: any) {
    event.newData.projectId = this.adminState.activeProjectId
  }

  async refreshDeps(): Promise<void> {
    this.sowTypes = await adminDataService.getData('statement-of-work-types', 'sowTypeName', undefined, this.adminState.activeProjectId)
  }
}
