import { ClinManagementDto, ClinManagementEditorDependencies, PaginationResult } from '@/types'
import { BaseHttpService } from './base-http-service'

class ClinService extends BaseHttpService {
  public getEmptyClinManagementDto(): ClinManagementDto {
    return {
      contractLineItemNumber: '',
      contractLineItemNumberTypeId: 0,
      contractLineItemNumberTypeName: '',
      description: '',
      id: 0,
      optionPeriods: [],
      projectId: 0,
    }
  }

  public getEmptyClinManagementEditorDependencies(): ClinManagementEditorDependencies {
    return {
      optionPeriods: [],
      clinTypes: [],
    }
  }

  public async getClinPage(orderBy: string, projectId: number, pageNum = 1, pageSize?: number): Promise<PaginationResult<ClinManagementDto>> {
    const params = new URLSearchParams()
    params.set('page', pageNum.toString())
    params.set('order_by', orderBy)
    params.append('include', '')
    if (pageSize) {
      params.set('page_size', pageSize.toString())
    }
    const uri = `/api/projects/${projectId}/contract-line-item-numbers?${params.toString()}`
    const response = await this.doApiGet<PaginationResult<ClinManagementDto>>(uri)

    if (response.success) {
      response.response!.meta.totalPages = Math.ceil(response.response!.meta.total / response.response!.meta.perPage)
      return response.response!
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }

  public async getEditorDependencies(projectId: number): Promise<ClinManagementEditorDependencies> {
    const uri = `/api/projects/${projectId}/editor-data/contract-line-item-numbers`
    const response = await this.doApiGet<ClinManagementEditorDependencies>(uri)
    if (response.success) {
      return response.response!
    } else {
      this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
    }
  }

  public async saveClinManagementRow(row: ClinManagementDto): Promise<void> {
    const uri = `/api/projects/${row.projectId}/contract-line-item-numbers`
    const response = await this.doApiPost(uri, JSON.stringify(row))
    if (response.success) {
      return
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }

  public async deleteClin(projectId: number, id: number): Promise<void> {
    const uri = `/api/projects/${projectId}/contract-line-item-numbers/${id}`
    const response = await this.doApiDelete(uri)
    if (response.success) {
      return
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }
}

export default new ClinService()
