






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Modal } from 'bootstrap'
import { v4 as uuidv4 } from 'uuid'

@Component({})
export default class GenericModal extends Vue {
  @Prop({ default: '' })
  public title!: string

  @Prop({ default: true })
  public useCancelIcon!: boolean

  @Prop({ default: '' })
  public cancelBtnText!: string

  @Prop({ default: false })
  public scrollable!: boolean

  @Prop({ default: () => { return {} }})
  public slotData!: any

  private id: string = uuidv4()
  private modal?: Modal

  mounted(): void {
    const el = document.getElementById(this.id)
    el!.addEventListener('hidden.bs.modal', () => {
      this.modal?.dispose()
      delete this.modal
      for (const key of Object.keys(this.slotData)) {
        Vue.set(this.slotData, key, '')
      }
      console.log('slot data', this.slotData)
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public emitAndClose(data: any): void {
    this.$emit('modalData', data)
    this.modal?.hide()
  }

  public show(): void {
    const el = document.getElementById(this.id)
    this.modal = new Modal(el!)
    this.modal.show()
  }
}
