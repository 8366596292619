






























































import { Modal } from 'bootstrap'
import { Component, Vue } from 'vue-property-decorator'
import { io, Socket } from "socket.io-client";
import uploadService from '@/services/upload-service'
import { FileUploadHistoryContract } from '@/types/dtos';
import { FileUploadResultContract } from '@/types';

@Component({})
export default class FileUploadResultModal extends Vue {

  private modal?: Modal
  private socket?: Socket
  uploadResult: FileUploadResultContract = this.getEmptyUploadResult()
  historyId = 0
  progress = ''
  errors: string[] = []
  messages: string[] = []
  currentStatus = 'pending'

  async mounted(): Promise<void> {
    const el = document.getElementById('file-upload-result-modal')
    el!.addEventListener('hidden.bs.modal', () => {
      this.modal?.dispose()
      delete this.modal
      this.socket?.close()
      delete this.socket
      this.progress = ''
      this.errors = []
      this.messages = []
      this.uploadResult = this.getEmptyUploadResult()

    })
    el!.addEventListener('shown.bs.modal', () => {
      this.socket = io(uploadService.getWsUrl(), {
        transports: ['polling', 'websocket'],
      })
      this.socket.on('file-upload-history', (history: FileUploadHistoryContract) => {
        if (history.id === this.historyId) {
          console.log(history)
          this.currentStatus = history.fileStatus
          switch (history.fileStatus) {
            case 'pending':
              this.progress = `Waiting for processing`
              break
            case 'processing':
              this.progress = `Processed ${history.recordsProcessed} of ${history.totalRecords} records`
              break
            case 'complete':
              this.progress = `Processed ${history.recordsProcessed} of ${history.totalRecords} records.  ${history.processingResults.succeeded} successful, ${history.processingResults.failed} failed`
              this.errors = []
              for (const error of history.processingResults.errors) {
                if (error.lineNumber || error.error) {
                  this.errors.push(`Line ${error.lineNumber}: ${error.error}`)
                } else {
                  this.errors.push(error)
                }
              }
              this.messages = history.processingResults.messages
              break
          }
        }
      })
    })
  }

  private getEmptyUploadResult(): FileUploadResultContract {
    return {
      result: 'success',
      id: 0,
      originalFilename: '',
      destinationFilename: '',
      size: 0,
    }
  }

  public show(fileUploadResult: FileUploadResultContract): void {
    this.uploadResult = fileUploadResult
    this.historyId = fileUploadResult.id
    const el = document.getElementById('file-upload-result-modal')
    this.modal = new Modal(el!)
    this.modal.show()
  }
}
