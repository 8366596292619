




































import { Component, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import adminDataService from '@/services/admin-data-service'

@Component({
  components: {
    EditableList,
  }
})
export default class Users extends Vue {
  selectedRole: number | null = null
  roles: any[] = []

  async mounted() {
    this.roles = await adminDataService.getData('project-roles', 'name')
  }

  addUserRole(slotProps: any) {
    if (!this.selectedRole) return
    slotProps.setDirty()
    const user = slotProps.data
    const selectedRole = this.roles.filter(x => x.id == this.selectedRole)[0]
    this.initProjectRoles(user)
    user.projectRoles.push(selectedRole)
    this.selectedRole = null
  }

  removeUserRole(slotProps: any, userRole: any) {
    slotProps.setDirty()
    const user = slotProps.data
    this.initProjectRoles(user)
    user.projectRoles.splice(user.projectRoles.indexOf(userRole), 1)
  }

  availableRoles(user: any) {
    this.initProjectRoles(user)
    var result =  this.roles.filter(x => user.projectRoles.find((y: any) => x.id === y.id) == undefined)
    return result;
  }

  initProjectRoles(user: any) {
    if (!user.projectRoles) {
      this.$set(user, 'projectRoles', [])
    }
  }
}
