var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("h4", [_vm._v("Resource Management")]),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary",
            attrs: { type: "button", disabled: _vm.loading },
            on: {
              click: function($event) {
                return _vm.refreshData()
              }
            }
          },
          [
            !_vm.loading
              ? _c("i", { staticClass: "bi-arrow-clockwise" })
              : _c(
                  "div",
                  {
                    staticClass: "spinner-border spinner-border-sm",
                    attrs: { role: "status" }
                  },
                  [
                    _c("span", { staticClass: "visually-hidden" }, [
                      _vm._v("Loading...")
                    ])
                  ]
                ),
            _c("span", { staticClass: "ms-1" }, [_vm._v("Refresh")])
          ]
        )
      ])
    ]),
    _c("div", { staticStyle: { height: "75vh", overflow: "scroll" } }, [
      _c(
        "table",
        { staticClass: "table table-sm table-striped resource-management" },
        [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th"),
                _c("th", [_vm._v("Resource Name")]),
                _vm._l(_vm.resources, function(res) {
                  return _c(
                    "th",
                    {
                      key: res.id,
                      staticClass: "text-center normalized-value"
                    },
                    [_vm._v(" " + _vm._s(res.resourceName) + " ")]
                  )
                })
              ],
              2
            ),
            _c(
              "tr",
              [
                _c("th"),
                _c("th", [_vm._v("Resource Type")]),
                _vm._l(_vm.resources, function(res) {
                  return _c(
                    "th",
                    { key: res.id, staticClass: "text-capitalize text-center" },
                    [_vm._v(" " + _vm._s(res.resourceType) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "tbody",
            _vm._l(_vm.costCenterTypes.data, function(cct, cctIndex) {
              return _c(
                "tr",
                { key: cct.id },
                [
                  cctIndex === 0
                    ? _c(
                        "th",
                        {
                          staticClass: "vertical text-end",
                          attrs: { rowspan: _vm.pageSize }
                        },
                        [_vm._v("Cost Center Type")]
                      )
                    : _vm._e(),
                  _c("td", { staticClass: "normalized-value" }, [
                    _vm._v(_vm._s(cct.costCenterType))
                  ]),
                  _vm._l(_vm.resources, function(res, resIndex) {
                    return _c(
                      "td",
                      {
                        key: "checkbox-" + cct.id + "-" + res.id,
                        staticClass: "text-center"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.associations[cctIndex][resIndex],
                              expression: "associations[cctIndex][resIndex]"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.associations[cctIndex][resIndex]
                            )
                              ? _vm._i(
                                  _vm.associations[cctIndex][resIndex],
                                  null
                                ) > -1
                              : _vm.associations[cctIndex][resIndex]
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.associations[cctIndex][resIndex],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.associations[cctIndex],
                                        resIndex,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.associations[cctIndex],
                                        resIndex,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.associations[cctIndex],
                                    resIndex,
                                    $$c
                                  )
                                }
                              },
                              function($event) {
                                return _vm.updateAssociation(cct, res)
                              }
                            ]
                          }
                        })
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        ]
      )
    ]),
    _vm.costCenterTypes.meta.total > 0
      ? _c(
          "nav",
          { staticClass: "mt-2", attrs: { "aria-label": "Data pagination" } },
          [
            _c(
              "ul",
              {
                staticClass: "pagination justify-content-center",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.costCenterTypes.meta.currentPage === 1 ||
                          _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Previous" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page - 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-left" })]
                    )
                  ]
                ),
                _vm._l(_vm.costCenterTypes.meta.totalPages, function(pg) {
                  return _c(
                    "li",
                    {
                      key: pg,
                      class: [
                        "page-item",
                        {
                          active: pg === _vm.costCenterTypes.meta.currentPage,
                          disabled: _vm.loading
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              return _vm.refreshData(pg)
                            }
                          }
                        },
                        [_vm._v(_vm._s(pg))]
                      )
                    ]
                  )
                }),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.costCenterTypes.meta.currentPage ===
                          _vm.costCenterTypes.meta.lastPage
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Next" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page + 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-right" })]
                    )
                  ]
                )
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }