



















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import AdminState from '@/store/modules/admin'
import { getModule } from 'vuex-module-decorators'
import { ContractDataRequirementContract, ContractLineItemNumberContract, StatementOfWorkContract } from '@/types/dtos'
import adminDataService from '@/services/admin-data-service'
import differenceBy from 'lodash/differenceBy'
import LimitedTextDisplay from '@/components/util/LimitedTextDisplay.vue'

@Component({
  components: {
    EditableList,
    LimitedTextDisplay,
  }
})
export default class SowClinCdrlCrosswalk extends Vue {
  adminState: AdminState = getModule(AdminState)

  allClins: ContractLineItemNumberContract[] = []
  allCdrls: ContractDataRequirementContract[] = []

  async mounted(): Promise<void> {
    await this.refreshDeps()
  }

  @Watch('adminState.activeProjectIndex')
  async refreshDeps(): Promise<void> {
    this.allClins = await adminDataService.getData('contract-line-item-numbers', 'contractLineItemNumber', undefined, this.adminState.activeProjectId)
    this.allCdrls = await adminDataService.getData('contract-data-requirements', 'cdrlNumber', undefined, this.adminState.activeProjectId)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addClin(data: StatementOfWorkContract, event: any, setDirty: () => void): Promise<void> {
    const id = Number(event.target.value)
    const clin = this.allClins.find(c => c.id === id)
    if (clin) {
      if (!data.contractLineItemNumbers) {
        data.contractLineItemNumbers = []
      }
      data.contractLineItemNumbers.push(clin)
      setDirty()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addCdrl(data: StatementOfWorkContract, event: any, setDirty: () => void): Promise<void> {
    const id = Number(event.target.value)
    const cdrl = this.allCdrls.find(c => c.id === id)
    if (cdrl) {
      if (!data.contractDataRequirements) {
        data.contractDataRequirements = []
      }
      data.contractDataRequirements.push(cdrl)
      setDirty()
    }
  }

  async removeClin(data: StatementOfWorkContract, clinId: number, setDirty: () => void): Promise<void> {
    if (data.contractLineItemNumbers) {
      data.contractLineItemNumbers.splice(data.contractLineItemNumbers.findIndex(x => x.id === clinId), 1)
      setDirty()
    }
  }

  async removeCdrl(data: StatementOfWorkContract, cdrlId: number, setDirty: () => void): Promise<void> {
    if (data.contractDataRequirements) {
      data.contractDataRequirements.splice(data.contractDataRequirements.findIndex(x => x.id === cdrlId), 1)
      setDirty()
    }
  }

  public availableClins(data: StatementOfWorkContract): ContractLineItemNumberContract[] {
    const acs = differenceBy(this.allClins, data.contractLineItemNumbers || [], 'id')
    return acs
  }

  public availableCdrls(data: StatementOfWorkContract): ContractDataRequirementContract[] {
    const acs = differenceBy(this.allCdrls, data.contractDataRequirements || [], 'id')
    return acs
  }
}
