import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../index'
import {
    BoeBokContract, BoeBokEstimateContract, BoeBokEstimateMonthContract,
    EstimationDependency, NewBoeBok,
    OptionPeriodContract, ProjectCostCenterContract,
    ProjectWorkBreakdownStructureContract
} from '@/types/dtos'
import ProjectWorkBreakdownStructureService from '@/services/project-work-breakdown-structure-service'
import BoeBokService from '@/services/boe-bok-service'
import BoeBokEstimateService from '@/services/boe-bok-estimate-service'
import BoeBokEstimateMonthService from '@/services/boe-bok-estimate-month-service'

export interface OptionPeriodKeys {
    [key: string]: {
        optionPeriod: OptionPeriodContract
        statementOfWorks: string[]
        contractLineItemNumbers: string[]
        costCenters: EstimationCostCenterData[]
        clinPoPStart: Date|string
        clinPoPEnd: Date|string
    }
}

export interface EstimationCostCenterData {
    costCenter: ProjectCostCenterContract
    boeBok: BoeBokContract|NewBoeBok
}

@Module({
    name: 'project-estimation',
    store,
    dynamic: true,
})
export default class ProjectEstimationState extends VuexModule {
    workBreakdownStructures: ProjectWorkBreakdownStructureContract[] = []
    workBreakdownStructuresFilteredList: ProjectWorkBreakdownStructureContract[] = []
    workBreakdownStructuresLoading = false
    workBreakdownStructureId = -1
    workBreakdownStructureEstimationData: ProjectWorkBreakdownStructureContract|null = null
    originalWorkBreakdownStructureEstimationData: ProjectWorkBreakdownStructureContract|null = null
    optionPeriods: OptionPeriodKeys = {}
    estimationDependencies: EstimationDependency|null = null

    @Mutation
    setWorkBreakdownStructuresLoading(val: boolean): void {
        this.workBreakdownStructuresLoading = val
    }

    @Mutation
    setWorkBreakdownStructures(workBreakdownStructures: ProjectWorkBreakdownStructureContract[]): void {
        this.workBreakdownStructures = workBreakdownStructures
    }

    @Mutation
    setWorkBreakdownStructuresFilteredList(filterData: { filterType: 'all'|'noOwner'; searchText: string }): void {
        this.workBreakdownStructuresFilteredList = this.workBreakdownStructures

        if (filterData.filterType === 'noOwner') {
            this.workBreakdownStructuresFilteredList = this.workBreakdownStructuresFilteredList.filter(wbs => !wbs.responsibleOwnerId)
        }

        if (filterData.searchText) {
            this.workBreakdownStructuresFilteredList = this.workBreakdownStructuresFilteredList.filter(wbsfl => wbsfl.wbsNumber.toLowerCase().indexOf(filterData.searchText.toLowerCase()) !== -1 ||
                wbsfl.description.toLowerCase().indexOf(filterData.searchText.toLowerCase()) !== -1)
        }
    }

    @Mutation
    setWorkBreakdownStructureEstimationData(workBreakdownStructureEstimationData: ProjectWorkBreakdownStructureContract|null): void {
        this.workBreakdownStructureEstimationData = workBreakdownStructureEstimationData ?
            JSON.parse(JSON.stringify(workBreakdownStructureEstimationData)) :
            null
    }

    @Mutation
    setOriginalWorkBreakdownStructureEstimationData(): void {
        this.originalWorkBreakdownStructureEstimationData = this.workBreakdownStructureEstimationData ?
            JSON.parse(JSON.stringify(this.workBreakdownStructureEstimationData)) :
            null
    }

    @Mutation
    setEstimationDependencies(estimationDependencies: EstimationDependency): void {
        this.estimationDependencies = estimationDependencies
    }

    @Mutation
    setWorkBreakdownStructureId(id: number): void {
        this.workBreakdownStructureId = id
    }

    @Mutation
    setOptionPeriods(optionPeriods: OptionPeriodKeys): void {
        this.optionPeriods = optionPeriods
    }

    @Mutation
    setBoeBok(data: { optionPeriodKey: string; boeBok: BoeBokContract }): void {
        const opCC = this.optionPeriods[data.optionPeriodKey].costCenters.find(cc => cc.costCenter.id === data.boeBok.projectCostCenterId)
        if (opCC) {
            opCC.boeBok = data.boeBok
        }
    }

    @Mutation
    public setClinPoPStart(data: { optionPeriodKey: string; clinPoPStart: Date }): void {
        this.optionPeriods[data.optionPeriodKey].clinPoPStart = data.clinPoPStart
    }

    @Mutation setClinPoPEnd(data: { optionPeriodKey: string; clinPoPEnd: Date }): void {
        this.optionPeriods[data.optionPeriodKey].clinPoPEnd = data.clinPoPEnd
    }

    @Action
    public clearData(): void {
        this.context.commit('setWorkBreakdownStructureEstimationData', null)
        this.context.commit('setOriginalWorkBreakdownStructureEstimationData', null)
        this.context.commit('setOptionPeriods', {})
    }

    @Action
    public getClinPoPStart(optionPeriodKey: string): void {
        let start: Date|string = ''

        for (const costCenterData of this.optionPeriods[optionPeriodKey].costCenters) {
            if (costCenterData.boeBok.periodOfPerformanceStart) {
                if (!start || (costCenterData.boeBok.periodOfPerformanceStart && costCenterData.boeBok.periodOfPerformanceStart < start)) {
                    start = costCenterData.boeBok.periodOfPerformanceStart
                }
            }
        }

        this.context.commit('setClinPoPStart', { optionPeriodKey: optionPeriodKey, clinPoPStart: start })
    }

    @Action
    public getClinPoPEnd(optionPeriodKey: string): void {
        let end: Date|string = ''

        for (const costCenterData of this.optionPeriods[optionPeriodKey].costCenters) {
            if (costCenterData.boeBok.periodOfPerformanceEnd) {
                if (!end || (costCenterData.boeBok.periodOfPerformanceEnd && costCenterData.boeBok.periodOfPerformanceEnd > end)) {
                    end = costCenterData.boeBok.periodOfPerformanceEnd
                }
            }
        }

        this.context.commit('setClinPoPEnd', { optionPeriodKey: optionPeriodKey, clinPoPEnd: end })
    }

    @Action
    public async saveBoeBok(data: { optionPeriodKey: string; boeBok: BoeBokContract|NewBoeBok }): Promise<void> {
        try {
            const toCreateUpdatedBoeBok: Partial<BoeBokContract|NewBoeBok> = {
                projectWorkBreakdownStructureId: data.boeBok.projectWorkBreakdownStructureId,
                optionPeriodId: data.boeBok.optionPeriodId,
                projectCostCenterId: data.boeBok.projectCostCenterId,
                periodOfPerformanceStart: data.boeBok.periodOfPerformanceStart as Date,
                periodOfPerformanceEnd: data.boeBok.periodOfPerformanceEnd as Date,
            }

            let newUpdatedBoeBok

            if (data.boeBok.id === -1) {
                newUpdatedBoeBok = await BoeBokService.createBoeBok(toCreateUpdatedBoeBok as BoeBokContract)
                newUpdatedBoeBok.boeBokEstimates = []
            } else {
                newUpdatedBoeBok = await BoeBokService.updateBoeBok(data.boeBok.id, toCreateUpdatedBoeBok as BoeBokContract)
            }

            this.context.commit('setBoeBok', { optionPeriodKey: data.optionPeriodKey, boeBok: newUpdatedBoeBok })
        } catch (err: any) {
            console.error(err)
        }
    }

    @Action
    public async saveBoeBokEstimate(data: { boeBokEstimate: Partial<BoeBokEstimateContract> }): Promise<number> {
        try {
            let newUpdatedBoeBokEstimate

            if (!data.boeBokEstimate.id) {
                newUpdatedBoeBokEstimate = await BoeBokEstimateService.createBoeBokEstimate(data.boeBokEstimate)
            } else {
                newUpdatedBoeBokEstimate = await BoeBokEstimateService.updateBoeBokEstimate(data.boeBokEstimate.id, data.boeBokEstimate)
            }

            return newUpdatedBoeBokEstimate.id
        } catch (err: any) {
            console.error(err)
            return -1
        }
    }

    @Action
    public async deleteBoeBokEstimate(id: number): Promise<void> {
        try {
            await BoeBokEstimateService.deleteBoeBokEstimate(id)
        } catch (err: any) {
            console.error(err)
        }
    }

    @Action
    public async saveBoeBokEstimateMonths(data: { boeBokEstimateId: number; boeBokEstimateMonths: Partial<BoeBokEstimateMonthContract>[]; saveType: 'create'|'update' }): Promise<void> {
        try {
            if (data.saveType === 'create') {
                await BoeBokEstimateMonthService.createBoeBokEstimateMonths(data.boeBokEstimateId, data.boeBokEstimateMonths)
            } else {
                await BoeBokEstimateMonthService.updateBoeBokEstimateMonths(data.boeBokEstimateId, data.boeBokEstimateMonths)
            }
        } catch (err: any) {
            console.log(err)
        }
    }

    @Action
    public async loadWorkBreakdownStructures(projectId: number): Promise<void> {
        try {
            this.context.commit('setWorkBreakdownStructuresLoading', true)
            const workBreakdownStructures = await ProjectWorkBreakdownStructureService.getProjectWorkBreakdownStructures(projectId)
            this.context.commit('setWorkBreakdownStructures', workBreakdownStructures)
            this.context.commit('setWorkBreakdownStructuresFilteredList', { filterType: 'all', searchText: '' })
            await this.loadEstimationDependencies(projectId)
        } catch (err: any) {
            console.error(err)
        } finally {
            this.context.commit('setWorkBreakdownStructuresLoading', false)
        }
    }

    @Action
    public async loadWorkBreakdownEstimationStructureData(): Promise<void> {
        try {
            const workBreakdownStructureEstimationData = await ProjectWorkBreakdownStructureService.getProjectWorkBreakdownStructure(this.workBreakdownStructureId, true)
            this.context.commit('setWorkBreakdownStructureEstimationData', workBreakdownStructureEstimationData)
            this.context.commit('setOriginalWorkBreakdownStructureEstimationData')
            this.loadOptionPeriods()
        } catch (err: any) {
            console.error(err)
        }
    }

    @Action
    public loadOptionPeriods(): void {
        this.context.commit('setOptionPeriods', {})
        const optionPeriods: OptionPeriodKeys = {}

        if (this.estimationDependencies && this.workBreakdownStructureEstimationData && this.workBreakdownStructureEstimationData.workBreakdownStructureCategory?.statementOfWorks?.length) {
            const pwbsId = this.workBreakdownStructureEstimationData.id

            for (const statementOfWork of this.workBreakdownStructureEstimationData.workBreakdownStructureCategory.statementOfWorks) {
                if (statementOfWork.contractLineItemNumbers?.length) {
                    for (const contractLineItemNumber of statementOfWork.contractLineItemNumbers) {
                        if (contractLineItemNumber.optionPeriods?.length) {
                            for (const optionPeriod of contractLineItemNumber.optionPeriods) {
                                let clinPoPStart: Date|string|null = null
                                let clinPoPEnd: Date|string|null = null

                                if (!optionPeriods[optionPeriod.optionPeriod]) {
                                    optionPeriods[optionPeriod.optionPeriod] = {
                                        optionPeriod: optionPeriod,
                                        statementOfWorks: [
                                            statementOfWork.sowNumber
                                        ],
                                        contractLineItemNumbers: [
                                            contractLineItemNumber.contractLineItemNumber,
                                        ],
                                        costCenters: [],
                                        clinPoPStart: '',
                                        clinPoPEnd: '',
                                    }

                                    for (const costCenter of this.estimationDependencies.costCenters) {
                                        const opId = optionPeriod.id
                                        const ccId = costCenter.id

                                        let boeBok: BoeBokContract|NewBoeBok|null = null

                                        if (this.workBreakdownStructureEstimationData.boeBoks?.length) {
                                            const existingBoeBok = this.workBreakdownStructureEstimationData.boeBoks.find(boeb => boeb.projectWorkBreakdownStructureId === pwbsId &&
                                                boeb.optionPeriodId === opId && boeb.projectCostCenterId === ccId)

                                            if (existingBoeBok) {
                                                if (!clinPoPStart || (existingBoeBok.periodOfPerformanceStart && existingBoeBok.periodOfPerformanceStart < clinPoPStart)) {
                                                    clinPoPStart = existingBoeBok.periodOfPerformanceStart
                                                }
                                                if (!clinPoPEnd || (existingBoeBok.periodOfPerformanceEnd && existingBoeBok.periodOfPerformanceEnd > clinPoPEnd)) {
                                                    clinPoPEnd = existingBoeBok.periodOfPerformanceEnd
                                                }
                                                boeBok = existingBoeBok
                                            }
                                        }

                                        if (!boeBok) {
                                            boeBok = {
                                                id: -1,
                                                projectWorkBreakdownStructureId: pwbsId,
                                                optionPeriodId: opId,
                                                projectCostCenterId: ccId,
                                                periodOfPerformanceStart: null,
                                                periodOfPerformanceEnd: null,
                                                boeBokEstimates: [],
                                            }
                                        }

                                        optionPeriods[optionPeriod.optionPeriod].clinPoPStart = clinPoPStart || ''
                                        optionPeriods[optionPeriod.optionPeriod].clinPoPEnd = clinPoPEnd || ''
                                        optionPeriods[optionPeriod.optionPeriod].costCenters.push({
                                            costCenter: JSON.parse(JSON.stringify(costCenter)),
                                            boeBok: boeBok,
                                        })
                                    }
                                } else {
                                    if (!optionPeriods[optionPeriod.optionPeriod].statementOfWorks.find(sow => sow === statementOfWork.sowNumber)) {
                                        optionPeriods[optionPeriod.optionPeriod].statementOfWorks.push(statementOfWork.sowNumber)
                                    }
                                    if (!optionPeriods[optionPeriod.optionPeriod].contractLineItemNumbers.find(clin => clin === contractLineItemNumber.contractLineItemNumber)) {
                                        optionPeriods[optionPeriod.optionPeriod].contractLineItemNumbers.push(contractLineItemNumber.contractLineItemNumber)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        this.context.commit('setOptionPeriods', optionPeriods)
    }

    @Action
    public async loadEstimationDependencies(projectId: number): Promise<void> {
        try {
            const estimationDependencies = await ProjectWorkBreakdownStructureService.getEstimationDependencies(projectId)
            this.context.commit('setEstimationDependencies', estimationDependencies)
        } catch (err: any) {
            console.error(err)
        }
    }

    @Action
    public async updateWorkBreakdownStructureData(): Promise<void> {
        try {
            const partialWbsData: Partial<ProjectWorkBreakdownStructureContract> = {
                taskDescription: this.workBreakdownStructureEstimationData ?
                    this.workBreakdownStructureEstimationData.taskDescription :
                    '',
                deliverables: this.workBreakdownStructureEstimationData ?
                    this.workBreakdownStructureEstimationData.deliverables :
                    '',
                groundRules: this.workBreakdownStructureEstimationData ?
                    this.workBreakdownStructureEstimationData.groundRules :
                    '',
                laborEstimates: this.workBreakdownStructureEstimationData ?
                    this.workBreakdownStructureEstimationData.laborEstimates :
                    ''
            }

            await ProjectWorkBreakdownStructureService.updateProjectWorkBreakdownStructure(this.workBreakdownStructureId, partialWbsData)
            await this.loadWorkBreakdownEstimationStructureData()
        } catch (err: any) {
            console.error(err)
        }
    }
}
