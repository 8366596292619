var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editable-list", {
        attrs: {
          dataModelUri: "project-roles",
          sortProperty: "sortOrder",
          listTitle:
            "Project Roles for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          pageSize: 100,
          dataModelProjectBased: true
        },
        on: { onAddNew: _vm.onAddNew },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("Sort Order")
                ]),
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("Name")]),
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("Description")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c(
                        "div",
                        { staticClass: "col input-group has-validation" },
                        [
                          !slotProps.isEditing
                            ? _c("span", { staticClass: "prop-display" }, [
                                _vm._v(_vm._s(slotProps.data.sortOrder))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.sortOrder,
                                    expression: "slotProps.data.sortOrder"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: {
                                  type: "number",
                                  min: ".01",
                                  step: "0.01",
                                  required: ""
                                },
                                domProps: { value: slotProps.data.sortOrder },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "sortOrder",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "This field is required and must be a number greater than 0."
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col input-group has-validation" },
                        [
                          !slotProps.isEditing
                            ? _c(
                                "span",
                                {
                                  staticClass: "prop-display normalized-value"
                                },
                                [_vm._v(_vm._s(slotProps.data.name))]
                              )
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.name,
                                    expression: "slotProps.data.name"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: { type: "text", required: "" },
                                domProps: { value: slotProps.data.name },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("This field is required.")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col input-group has-validation" },
                        [
                          !slotProps.isEditing
                            ? _c("span", { staticClass: "prop-display" }, [
                                _vm._v(_vm._s(slotProps.data.description))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.description,
                                    expression: "slotProps.data.description"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: { type: "text", required: "" },
                                domProps: { value: slotProps.data.description },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("This field is required.")
                          ])
                        ]
                      )
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }