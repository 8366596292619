













































import { Component, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import AdminState from '@/store/modules/admin'
import { getModule } from 'vuex-module-decorators'
import RowState from '@/store/modules/row'

@Component({
  components: {
    EditableList,
  }
})
export default class SowTypes extends Vue {
  adminState: AdminState = getModule(AdminState)
  rowState: RowState = getModule(RowState)

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onAddNew(event: any) {
    event.newData.projectId = this.adminState.activeProjectId
  }
}
