var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editable-list", {
    attrs: {
      dataModelUri: _vm.dataModelUri,
      sortProperty: "sortOrder",
      listTitle:
        "WBS Management for " +
        (_vm.isProjectLevel
          ? _vm.adminState.activeProjectName
          : _vm.adminState.activeCompanyName),
      uri: "wbs-management",
      includeRelated: _vm.relatedModels,
      dataModelProjectBased: _vm.isProjectLevel
    },
    scopedSlots: _vm._u([
      {
        key: "row-header-content",
        fn: function() {
          return [
            _c("div", { staticClass: "fw-bold col-1" }, [_vm._v("Sort Order")]),
            _c(
              "div",
              {
                class: [
                  "fw-bold",
                  { "col-2": !_vm.isProjectLevel, "col-1": _vm.isProjectLevel }
                ]
              },
              [_vm._v("WBS Number")]
            ),
            _c(
              "div",
              {
                class: [
                  "fw-bold",
                  { "col-3": !_vm.isProjectLevel, "col-2": _vm.isProjectLevel }
                ]
              },
              [_vm._v("Description")]
            ),
            _c(
              "div",
              {
                class: [
                  "fw-bold",
                  { "col-3": !_vm.isProjectLevel, "col-2": _vm.isProjectLevel }
                ]
              },
              [_vm._v("Category")]
            ),
            _vm.isProjectLevel
              ? [
                  _c("div", { staticClass: "fw-bold col-2" }, [
                    _vm._v("Responsible Owner")
                  ])
                ]
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  "fw-bold",
                  { "col-3": !_vm.isProjectLevel, "col-2": _vm.isProjectLevel }
                ]
              },
              [_vm._v("Template WBS Dictionary")]
            ),
            _vm.isProjectLevel
              ? [
                  _c("div", { staticClass: "fw-bold col-2" }, [
                    _vm._v("Tailored WBS Dictionary")
                  ])
                ]
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "row-content",
        fn: function(slotProps) {
          return [
            _c("div", { staticClass: "col-1" }, [
              !slotProps.isEditing
                ? _c("span", { staticClass: "prop-display" }, [
                    _vm._v(_vm._s(slotProps.data.sortOrder))
                  ])
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: slotProps.data.sortOrder,
                        expression: "slotProps.data.sortOrder",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "form-control form-control-sm me-1",
                    attrs: { type: "number", step: "0.1", required: "" },
                    domProps: { value: slotProps.data.sortOrder },
                    on: {
                      change: function($event) {
                        return slotProps.setDirty()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          slotProps.data,
                          "sortOrder",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
              _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("This field is required.")
              ])
            ]),
            _c(
              "div",
              {
                class: {
                  "col-2": !_vm.isProjectLevel,
                  "col-1": _vm.isProjectLevel
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input-group input-group-sm has-validation" },
                  [
                    !slotProps.isEditing
                      ? _c("span", { staticClass: "prop-display" }, [
                          _vm._v(_vm._s(slotProps.data.wbsNumber))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: slotProps.data.wbsNumber,
                              expression: "slotProps.data.wbsNumber"
                            }
                          ],
                          staticClass: "form-control form-control-sm me-1",
                          attrs: { type: "text", required: "" },
                          domProps: { value: slotProps.data.wbsNumber },
                          on: {
                            change: function($event) {
                              return slotProps.setDirty()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                slotProps.data,
                                "wbsNumber",
                                $event.target.value
                              )
                            }
                          }
                        }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("This field is required.")
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                class: {
                  "col-3": !_vm.isProjectLevel,
                  "col-2": _vm.isProjectLevel
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input-group input-group-sm has-validation" },
                  [
                    !slotProps.isEditing
                      ? _c("span", { staticClass: "prop-display" }, [
                          _vm._v(_vm._s(slotProps.data.description))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: slotProps.data.description,
                              expression: "slotProps.data.description"
                            }
                          ],
                          staticClass: "form-control form-control-sm me-1",
                          attrs: { type: "text", required: "" },
                          domProps: { value: slotProps.data.description },
                          on: {
                            change: function($event) {
                              return slotProps.setDirty()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                slotProps.data,
                                "description",
                                $event.target.value
                              )
                            }
                          }
                        }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("This field is required.")
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                class: {
                  "col-3": !_vm.isProjectLevel,
                  "col-2": _vm.isProjectLevel
                }
              },
              [
                _vm.isProjectLevel
                  ? _c("div", { staticClass: "input-group input-group-sm" }, [
                      !slotProps.isEditing
                        ? _c(
                            "span",
                            {
                              staticClass: "prop-display normalized-value",
                              attrs: {
                                title: _vm.safeGet(
                                  slotProps.data.workBreakdownStructureCategory,
                                  "categoryName"
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.safeGet(
                                    slotProps.data
                                      .workBreakdownStructureCategory,
                                    "categoryCode",
                                    "(none assigned)"
                                  )
                                )
                              )
                            ]
                          )
                        : _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    slotProps.data
                                      .projectWorkBreakdownStructureCategoryId,
                                  expression:
                                    "slotProps.data.projectWorkBreakdownStructureCategoryId"
                                }
                              ],
                              staticClass: "form-select form-select-sm",
                              attrs: { "aria-label": "WBS Categories" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      slotProps.data,
                                      "projectWorkBreakdownStructureCategoryId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    return slotProps.setDirty()
                                  }
                                ]
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select a Category")
                              ]),
                              _vm._l(_vm.editorDeps.wbsCategories, function(
                                opt
                              ) {
                                return _c(
                                  "option",
                                  { key: opt.id, domProps: { value: opt.id } },
                                  [
                                    _vm._v(
                                      _vm._s(opt.categoryCode) +
                                        " - " +
                                        _vm._s(opt.categoryName)
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                    ])
                  : _c("div", { staticClass: "input-group input-group-sm" }, [
                      !slotProps.isEditing
                        ? _c(
                            "span",
                            {
                              staticClass: "prop-display normalized-value",
                              attrs: {
                                title: _vm.safeGet(
                                  slotProps.data.workBreakdownStructureCategory,
                                  "categoryName"
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.safeGet(
                                    slotProps.data
                                      .workBreakdownStructureCategory,
                                    "categoryCode",
                                    "(none assigned)"
                                  )
                                )
                              )
                            ]
                          )
                        : _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    slotProps.data
                                      .workBreakdownStructureCategoryId,
                                  expression:
                                    "slotProps.data.workBreakdownStructureCategoryId"
                                }
                              ],
                              staticClass: "form-select form-select-sm",
                              attrs: { "aria-label": "WBS Categories" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      slotProps.data,
                                      "workBreakdownStructureCategoryId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    return slotProps.setDirty()
                                  }
                                ]
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select a Category")
                              ]),
                              _vm._l(_vm.editorDeps.wbsCategories, function(
                                opt
                              ) {
                                return _c(
                                  "option",
                                  { key: opt.id, domProps: { value: opt.id } },
                                  [
                                    _vm._v(
                                      _vm._s(opt.categoryCode) +
                                        " - " +
                                        _vm._s(opt.categoryName)
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                    ])
              ]
            ),
            _vm.isProjectLevel
              ? [
                  _c("div", { staticClass: "col-2" }, [
                    !slotProps.isEditing
                      ? _c(
                          "span",
                          { staticClass: "prop-display normalized-value" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  slotProps.data.responsibleOwner
                                    ? slotProps.data.responsibleOwner
                                        .firstName +
                                        " " +
                                        slotProps.data.responsibleOwner.lastName
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: slotProps.data.responsibleOwnerId,
                                expression: "slotProps.data.responsibleOwnerId"
                              }
                            ],
                            staticClass: "form-select form-select-sm",
                            attrs: { "aria-label": "Responsible Owner" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  slotProps.data,
                                  "responsibleOwnerId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Select Owner")
                            ]),
                            _vm._l(_vm.editorDeps.users, function(opt) {
                              return _c(
                                "option",
                                { key: opt.id, domProps: { value: opt.id } },
                                [
                                  _vm._v(
                                    _vm._s(opt.firstName) +
                                      " " +
                                      _vm._s(opt.lastName)
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                  ])
                ]
              : _vm._e(),
            _c(
              "div",
              {
                class: {
                  "col-3": !_vm.isProjectLevel,
                  "col-2": _vm.isProjectLevel
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input-group input-group-sm has-validation" },
                  [
                    !slotProps.isEditing
                      ? _c("limited-text-display", {
                          staticClass: "prop-display",
                          attrs: { text: slotProps.data.wbsDictionary }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: slotProps.data.wbsDictionary,
                              expression: "slotProps.data.wbsDictionary"
                            }
                          ],
                          staticClass: "form-control form-control-sm me-1",
                          attrs: { type: "text", required: "" },
                          domProps: { value: slotProps.data.wbsDictionary },
                          on: {
                            change: function($event) {
                              return slotProps.setDirty()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                slotProps.data,
                                "wbsDictionary",
                                $event.target.value
                              )
                            }
                          }
                        }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("This field is required.")
                    ])
                  ],
                  1
                )
              ]
            ),
            _vm.isProjectLevel
              ? [
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group input-group-sm has-validation"
                      },
                      [
                        !slotProps.isEditing
                          ? _c("limited-text-display", {
                              staticClass: "prop-display",
                              attrs: {
                                text: slotProps.data.tailoredWbsDictionary || ""
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.tailoredWbsDictionary,
                                  expression:
                                    "slotProps.data.tailoredWbsDictionary"
                                }
                              ],
                              staticClass: "form-control form-control-sm me-1",
                              attrs: { type: "text" },
                              domProps: {
                                value: slotProps.data.tailoredWbsDictionary
                              },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "tailoredWbsDictionary",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }