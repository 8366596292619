import { BaseHttpService } from './base-http-service'
import { BoeBokEstimateMonthContract } from '@/types/dtos'

class BoeBokEstimateMonthService extends BaseHttpService {
    public async createBoeBokEstimateMonths(boeBokEstimateId: number, boeBokEstimateMonths: Partial<BoeBokEstimateMonthContract>[]): Promise<BoeBokEstimateMonthContract[]> {
        const uri = '/api/boe-bok-estimate-months'
        const response = await this.doApiPost<BoeBokEstimateMonthContract[]>(uri, JSON.stringify({ boeBokEstimateId: boeBokEstimateId, data: boeBokEstimateMonths }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async updateBoeBokEstimateMonths(boeBokEstimateId: number, boeBokEstimateMonths: Partial<BoeBokEstimateMonthContract>[]): Promise<BoeBokEstimateMonthContract[]> {
        const uri = `/api/boe-bok-estimate-months`
        const response = await this.doApiPut<BoeBokEstimateMonthContract[]>(uri, JSON.stringify({ boeBokEstimateId: boeBokEstimateId, data: boeBokEstimateMonths }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }
}

export default new BoeBokEstimateMonthService()
