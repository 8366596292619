











































































import { CostCenterEditorDependencies, CostCenterManagementDto, CrudSaveEvent, PaginationResult } from '@/types'
import { BaseCostCenterTypeContract, CostCenterTypeContract, FunctionalAreaContract, ProjectCostCenterTypeContract } from '@/types/dtos'
import { Component, Watch } from 'vue-property-decorator'
import EditableList from '../../EditableList.vue'
import EditableRow from '../../EditableRow.vue'
import EditableRowHeader from '../../EditableRowHeader.vue'
import costCenterService from '@/services/cost-center-service'
import GenericModal from '@/components/GenericModal.vue'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import ProjectLevelAdminEditor from '../projects/ProjectLevelAdminEditor'
import AdminState from '@/store/modules/admin'

@Component({
  components: {
    EditableList,
    EditableRow,
    EditableRowHeader,
    GenericModal,
  }
})
export default class CostCenterManagement extends ProjectLevelAdminEditor {

  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)

  editorDeps: CostCenterEditorDependencies = costCenterService.getEmptyCostCenterEditorDependencies()
  paginationResult: PaginationResult<CostCenterManagementDto> = costCenterService.getEmptyPaginationResult<CostCenterManagementDto>()
  loading = false
  page = 1

  get baseDataModelUri(): string {
    return 'cost-centers'
  }

  get isCreating(): boolean {
    return this.paginationResult.data.length > 0 && this.paginationResult.data[0].id === 0
  }

  getCostCenterType(id: number): BaseCostCenterTypeContract {
    let dto: BaseCostCenterTypeContract|undefined

    dto = this.editorDeps.costCenterTypes.find((cct: BaseCostCenterTypeContract) => cct.id === id)
    if (dto) {
      return dto
    } else if (this.isProjectLevel) {
      return {} as ProjectCostCenterTypeContract
    } else {
      return {} as CostCenterTypeContract
    }
  }

  getFunctionalArea(id: number): FunctionalAreaContract {
    return this.editorDeps.functionalAreas.find(fa => fa.id === id) || {} as FunctionalAreaContract
  }

  created(): void {
    const query = new URLSearchParams(location.search)
    if (query.has('page')) {
      const pg = Number(query.get('page'))
      if (!isNaN(pg)) {
        this.page = pg
      }
    }
  }

  async mounted(): Promise<void> {
    await this.refreshEditorDeps()
    await this.refreshData()
  }

  async refreshEditorDeps(): Promise<void> {
    if (this.isProjectLevel) {
      this.editorDeps = await costCenterService.getEditorDependencies(this.adminState.activeProjectId)
    } else {
      this.editorDeps = await costCenterService.getEditorDependencies()
    }
  }

  @Watch('searchParams')
  public async onSearchParamsUpdate(): Promise<void> {
    console.log('search params refreshing data', this.page)
    await this.refreshData()
  }

  @Watch('adminState.activeProjectId')
  public async onProjectChange(): Promise<void> {
    if (this.isProjectLevel) {
      await this.refreshData()
    }
  }

  public async refreshData(pageNum?: number): Promise<void> {
    try {
      if (pageNum && pageNum !== this.page) {
        this.page = pageNum
        this.$router.push({ path: `/cost-centers`, query: { page: this.page.toString() }})
      }
      this.loading = true
      this.paginationResult = await costCenterService.getCostCenterPage(this.adminState.activeProjectId, 'costCenterNumber', this.page, 10)
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error loading records: ${err.message}`)
    } finally {
      this.loading = false
    }
  }

  // public onAddNew(): void {
  //   this.addNewDialog.show()
  // }

  // // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // public addNewSelected(data: any): void {
  //   const roles = this.editorDeps.projectRoles.map(r => { return { id: r.id, name: r.name, enabled: false } })
  //   if (data.id) {
  //     const cc = this.editorDeps.costCenters.find((cc) => cc.id === data.id)!
  //     this.paginationResult.data.unshift({
  //       id: cc.id,
  //       isNew: true,
  //       projectId: this.adminState.activeProjectId,
  //       costCenterNumber: cc.costCenterNumber,
  //       name: cc.name,
  //       costCenterTypeId: cc.costCenterTypeId,
  //       functionalAreaId: cc.functionalAreaId,
  //       roles,
  //     })
  //   } else {
  //     this.paginationResult.data.unshift({ id: 0, isNew: true, roles, })
  //   }
  // }

  public async onSave(event: CrudSaveEvent): Promise<void> {
    const ccmDto = event.data as CostCenterManagementDto
    try {
      await costCenterService.saveCostCenterManagementRow(this.adminState.activeProjectId, ccmDto)
      event.component.actionComplete('Save')
    } catch (err: any) {
      event.component.actionComplete('Save', err.message || '')
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onCancel(event: any): void {
    if (event.isNew) {
      this.paginationResult.data.shift()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onDelete(event: any): Promise<void> {
    try {
      await costCenterService.deleteCostCenter(this.adminState.activeProjectId, event.data.id)
      event.component.actionComplete('Delete')
      await this.refreshData()
    } catch (err: any) {
      event.component.actionComplete('Delete', err.message || '')
    }
  }
}
