














import { Component, Vue, Watch } from 'vue-property-decorator'
import AdminState from '@/store/modules/admin'
import ProjectEstimationState from '@/store/modules/project-estimation-state'
import { getModule } from 'vuex-module-decorators'
import WorkBreakdownStructureList from '@/components/admin/projects/WorkBreakdownStructureList.vue'
import WorkBreakdownStructureEstimation from '@/components/admin/projects/WorkBreakdownStructureEstimation.vue'

@Component({
  components: {
    WorkBreakdownStructureList,
    WorkBreakdownStructureEstimation,
  }
})
export default class ProjectEstimation extends Vue {
  adminState: AdminState = getModule(AdminState)
  projectEstimationState: ProjectEstimationState = getModule(ProjectEstimationState)

  @Watch('adminState.activeProjectId')
  public async onProjectChange(): Promise<void> {
    await this.projectEstimationState.loadWorkBreakdownStructures(this.adminState.activeProjectId)
  }

  @Watch('projectEstimationState.workBreakdownStructureId')
  public async onWorkBreakdownStructureChange(): Promise<void> {
    if (this.projectEstimationState.workBreakdownStructureId !== -1) {
      this.projectEstimationState.clearData()
      await this.projectEstimationState.loadWorkBreakdownEstimationStructureData()
    }
  }

  async mounted(): Promise<void> {
    await this.onProjectChange()
  }
}
