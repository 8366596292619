






































import { Component, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'

@Component({
  components: {
    EditableList,
  }
})
export default class ProjectRoles extends Vue {

  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)

  /* no longer using drag to reorder, but keep code in case we need to do this elsewhere */
  // public async onOrderUpdated(event: OrderUpdatedEvent): Promise<void> {
  //   const ids = event.data.map(pr => pr.id)
  //   console.log('new id order is', ids)
  //   try {
  //     let sortOrder = 1
  //     for (const pr of event.data) {
  //       pr.sortOrder = sortOrder++
  //     }
  //     // save the changes
  //     const updatedRoles = await projectRoleService.updateSortOrder(ids)
  //
  //     // update the existing ui to match what's on the server.
  //     let listRole: any
  //     for (const ur of updatedRoles) {
  //       listRole = event.data.find(d => d.id === ur.id)
  //       if (listRole) {
  //         listRole.updatedAt = ur.updatedAt
  //       }
  //     }
  //     this.toastState.showSuccess('Role order updated successfully')
  //   } catch (err: any) {
  //     this.toastState.showError(`Error updating order of items: ${err.message}`)
  //   }
  // }

  public onAddNew(data: { newData: any, pageData: any[] }): void {
    console.log('data', data)
    data.newData.projectId = this.adminState.activeProjectId
    data.newData.sortOrder = data.pageData.length
  }
}
