import { render, staticRenderFns } from "./Resources.vue?vue&type=template&id=dfef7e5a&"
import script from "./Resources.vue?vue&type=script&lang=ts&"
export * from "./Resources.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/apps/proboe/proboe-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfef7e5a')) {
      api.createRecord('dfef7e5a', component.options)
    } else {
      api.reload('dfef7e5a', component.options)
    }
    module.hot.accept("./Resources.vue?vue&type=template&id=dfef7e5a&", function () {
      api.rerender('dfef7e5a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/admin/company/Resources.vue"
export default component.exports