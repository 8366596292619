import { ApiResponse, UploadEndpointContract } from '@/types'
import { BaseHttpService } from './base-http-service'

class ExportService extends BaseHttpService {
  public async exportEndpointData(endpoint: UploadEndpointContract, type: 'xlsx'|'csv', projectId: number|string): Promise<boolean> {
    let uri: string
    if (endpoint.projectBased) {
      uri = `/api/exports/projects/${projectId}/${endpoint.uri}/${type}`
    } else {
      uri = `/api/exports/company/${endpoint.uri}/${type}`
    }
    let response: ApiResponse<boolean>
    if (type === 'xlsx') {
      response = await this.downloadBinary(uri, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    } else {
      response = await this.download(uri, 'text/csv')
    }
    if (!response.success) {
      throw new Error(response.error!.message)
    } else {
      return true
    }
  }

  public async downloadImportTemplate(endpoint: UploadEndpointContract, type: 'xlsx'|'csv', projectId: number|string): Promise<boolean> {
    let uri: string
    if (endpoint.projectBased) {
      uri = `/api/exports/projects/${projectId}/${endpoint.uri}/${type}/template`
    } else {
      uri = `/api/exports/company/${endpoint.uri}/${type}/template`
    }
    let response: ApiResponse<boolean>
    if (type === 'xlsx') {
      response = await this.downloadBinary(uri, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    } else {
      response = await this.download(uri, 'text/csv')
    }
    if (!response.success) {
      throw new Error(response.error!.message)
    } else {
      return true
    }
  }
}

export default new ExportService()