export default class Common {
    public static dateOnly (date: string|Date|undefined|null): string {
        if (date === null || date === undefined) {
            return ''
        }

        if (typeof date === 'object') {
            date = date.toISOString()
        }

        if (date.indexOf('T') !== -1) {
            return date.split('T')[0]
        } else {
            return date.split(' ')[0]
        }
    }
}
