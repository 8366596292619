var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", attrs: { tabindex: "-1", id: _vm.id } },
    [
      _c(
        "div",
        {
          class: ["modal-dialog", { "modal-dialog-scrollable": _vm.scrollable }]
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("default", null, {
                  data: _vm.slotData,
                  emitAndClose: _vm.emitAndClose
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      title: "Close"
                    }
                  },
                  [
                    _vm.useCancelIcon
                      ? _c("i", { staticClass: "bi-x-lg" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.cancelBtnText) + " ")
                  ]
                ),
                _vm._t("buttons", null, {
                  emitAndClose: _vm.emitAndClose,
                  data: _vm.slotData
                })
              ],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }