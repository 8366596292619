




















import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import TWEEN from '@tweenjs/tween.js'
import GenericModal from '@/components/GenericModal.vue'

@Component({
  components: {
    GenericModal,
  }
})
export default class LimitedTextDisplay extends Vue {
  @Ref('genericModal')
  genericModal!: GenericModal

  @Prop({ required: true, type: String, default: '' })
  public text!: string

  @Prop({ required: false, type: String, default: 'expand' })
  public mode!: 'expand'|'modal'

  @Prop({ default: 25 })
  public cutoffLength!: number

  protected expanded = false
  protected id = uuidv4()
  protected currentLength = 0

  public get currentText(): string {
    if (!this.text) return ''
    return this.text.substr(0, this.currentLength) + (this.expanded || this.text.length <= this.cutoffLength ? '' : '...')
  }

  mounted(): void {
    this.currentLength = this.cutoffLength
  }

  toggleExpanded(): void {
    if (this.mode === 'modal') {
      this.genericModal.show()
    } else {
      this.expanded = !this.expanded
      const animate = function() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }
      new TWEEN.Tween(this)
          .to({ currentLength: this.expanded ? this.text.length : this.cutoffLength }, 300)
          .start()

      animate()
    }
  }
}
