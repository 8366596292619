var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editable-list", {
    attrs: {
      dataModelUri: _vm.dataModelUri,
      uri: "cost-centers",
      sortProperty: "costCenterNumber",
      listTitle:
        "Cost Centers for " +
        (_vm.isProjectLevel
          ? _vm.adminState.activeProjectName
          : _vm.adminState.activeCompanyName),
      dataModelProjectBased: _vm.isProjectLevel
    },
    scopedSlots: _vm._u([
      {
        key: "row-header-content",
        fn: function() {
          return [
            _c("div", { staticClass: "fw-bold col" }, [_vm._v("CC Number")]),
            _c("div", { staticClass: "fw-bold col" }, [_vm._v("Name")]),
            _c("div", { staticClass: "fw-bold col" }, [_vm._v("Type")]),
            _c("div", { staticClass: "fw-bold col" }, [
              _vm._v("Functional Area")
            ])
          ]
        },
        proxy: true
      },
      {
        key: "row-content",
        fn: function(slotProps) {
          return [
            !_vm.loading
              ? [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "input-group has-validation" }, [
                      !slotProps.isEditing
                        ? _c("span", { staticClass: "prop-display" }, [
                            _vm._v(_vm._s(slotProps.data.costCenterNumber))
                          ])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: slotProps.data.costCenterNumber,
                                expression: "slotProps.data.costCenterNumber"
                              }
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "text", required: "" },
                            domProps: {
                              value: slotProps.data.costCenterNumber
                            },
                            on: {
                              change: function($event) {
                                return slotProps.setDirty()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  slotProps.data,
                                  "costCenterNumber",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("This field is required.")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "input-group has-validation" }, [
                      !slotProps.isEditing
                        ? _c(
                            "span",
                            { staticClass: "prop-display normalized-value" },
                            [_vm._v(_vm._s(slotProps.data.name))]
                          )
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: slotProps.data.name,
                                expression: "slotProps.data.name"
                              }
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "text", required: "" },
                            domProps: { value: slotProps.data.name },
                            on: {
                              change: function($event) {
                                return slotProps.setDirty()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  slotProps.data,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("This field is required.")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "input-group has-validation" },
                      [
                        _vm.isProjectLevel
                          ? [
                              !slotProps.isEditing
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "prop-display normalized-value"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getCostCenterType(
                                            slotProps.data
                                              .projectCostCenterTypeId
                                          ).costCenterType
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            slotProps.data
                                              .projectCostCenterTypeId,
                                          expression:
                                            "slotProps.data.projectCostCenterTypeId"
                                        }
                                      ],
                                      staticClass: "form-select form-select-sm",
                                      attrs: {
                                        "aria-label": "Cost Center Types",
                                        required: ""
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              slotProps.data,
                                              "projectCostCenterTypeId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return slotProps.setDirty()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select a Type")
                                      ]),
                                      _vm._l(
                                        _vm.editorDeps.costCenterTypes,
                                        function(opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: { value: opt.id }
                                            },
                                            [_vm._v(_vm._s(opt.costCenterType))]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                            ]
                          : [
                              !slotProps.isEditing
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "prop-display normalized-value"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getCostCenterType(
                                            slotProps.data.costCenterTypeId
                                          ).costCenterType
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            slotProps.data.costCenterTypeId,
                                          expression:
                                            "slotProps.data.costCenterTypeId"
                                        }
                                      ],
                                      staticClass: "form-select form-select-sm",
                                      attrs: {
                                        "aria-label": "Cost Center Types",
                                        required: ""
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              slotProps.data,
                                              "costCenterTypeId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return slotProps.setDirty()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select a Type")
                                      ]),
                                      _vm._l(
                                        _vm.editorDeps.costCenterTypes,
                                        function(opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: { value: opt.id }
                                            },
                                            [_vm._v(_vm._s(opt.costCenterType))]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                            ],
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("This field is required.")
                        ])
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "input-group has-validation" },
                      [
                        _vm.isProjectLevel
                          ? [
                              !slotProps.isEditing
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "prop-display normalized-value"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFunctionalArea(
                                            slotProps.data
                                              .projectFunctionalAreaId
                                          ).functionalArea
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            slotProps.data
                                              .projectFunctionalAreaId,
                                          expression:
                                            "slotProps.data.projectFunctionalAreaId"
                                        }
                                      ],
                                      staticClass: "form-select form-select-sm",
                                      attrs: {
                                        "aria-label": "Functional Areas",
                                        required: ""
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              slotProps.data,
                                              "projectFunctionalAreaId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return slotProps.setDirty()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select a Functional Area")
                                      ]),
                                      _vm._l(
                                        _vm.editorDeps.functionalAreas,
                                        function(opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: { value: opt.id }
                                            },
                                            [_vm._v(_vm._s(opt.functionalArea))]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                            ]
                          : [
                              !slotProps.isEditing
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "prop-display normalized-value"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFunctionalArea(
                                            slotProps.data.functionalAreaId
                                          ).functionalArea
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            slotProps.data.functionalAreaId,
                                          expression:
                                            "slotProps.data.functionalAreaId"
                                        }
                                      ],
                                      staticClass: "form-select form-select-sm",
                                      attrs: {
                                        "aria-label": "Functional Areas",
                                        required: ""
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              slotProps.data,
                                              "functionalAreaId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return slotProps.setDirty()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select a Functional Area")
                                      ]),
                                      _vm._l(
                                        _vm.editorDeps.functionalAreas,
                                        function(opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: { value: opt.id }
                                            },
                                            [_vm._v(_vm._s(opt.functionalArea))]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                            ],
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("This field is required.")
                        ])
                      ],
                      2
                    )
                  ])
                ]
              : [
                  _c("span", { staticClass: "placeholder col" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col" })
                ]
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }