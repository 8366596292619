



































































































































import { ProjectWorkBreakdownStructureCategoryContract, ProjectWorkBreakdownStructureContract, StatementOfWorkContract } from '@/types/dtos'
import { Component, Vue, Watch } from 'vue-property-decorator'
import statementOfWorksService, { ClaimFilterType } from '@/services/statement-of-works-service'
import AdminState from '@/store/modules/admin'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import { PaginationMetaData, PaginationResult } from '@/types'
import adminDataService from '@/services/admin-data-service'
import LimitedTextDisplay from '@/components/util/LimitedTextDisplay.vue'
import PageNav from '@/components/util/PageNav.vue'
import { startCase } from '@/util/filters'

type SowClaimedWbsMapping = {
  statementOfWorkId: number
  projectWorkBreakdownStructureCategoryId: number
  claimed: boolean
  projectWorkBreakdownStructureId: number | null
  originalProjectWorkBreakdownStructureId: number | null
  projectWorkBreakdownStructureText?: string
}

type SowClaimMappingDto = {
  sow: StatementOfWorkContract
  wbsCategories: SowClaimedWbsMapping[]
}

@Component({
  components: {
    LimitedTextDisplay,
    PageNav,
  }
})
export default class SowClaimsMapping extends Vue {
  loading = false
  saving: { [index: number]: boolean } = {}
  savingMapping = false
  page = 1
  pageSize = 100
  allWbsCategories: ProjectWorkBreakdownStructureCategoryContract[] = []
  availableWbsCategories: ProjectWorkBreakdownStructureCategoryContract[] = []
  wbss: ProjectWorkBreakdownStructureContract[] = []
  sows: PaginationResult<StatementOfWorkContract> = adminDataService.getEmptyPaginationResult()
  sowMappings: SowClaimMappingDto[] = []
  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)
  currentTab: 'claims' | 'mappings' = 'claims'
  claimsFilter: null | ClaimFilterType = null
  wbsCatFilterId = 0
  mappedFilterText = ''
  ClaimFilterType = ClaimFilterType

  private capitalize(val: string) {
    return startCase(val)
  }

  private updateMappedFilter(wbsCategory: ProjectWorkBreakdownStructureCategoryContract|null) {
    this.wbsCatFilterId = wbsCategory ? wbsCategory.id : 0
    this.mappedFilterText = wbsCategory ? wbsCategory.categoryName : ''
    this.refreshData(1)
  }

  get pageMeta(): PaginationMetaData {
    return this.sows?.meta || adminDataService.getEmptyPaginationResult().meta
  }

  async mounted(): Promise<void> {
    const tabEls = document.querySelectorAll('a[data-bs-toggle="tab"]')
    for (const tabEl of tabEls) {
      tabEl.addEventListener('shown.bs.tab', async (event) => {
        const el = event.target as Element
        const tabTarget = el.attributes.getNamedItem('data-bs-target')!.value
        this.currentTab = tabTarget === '#claims' ? 'claims' : 'mappings'
        this.claimsFilter = null
        await this.refreshData(1)
      })
    }
    await this.refreshData(1, true)
  }

  @Watch('adminState.activeProjectId')
  async onProjectChanged(): Promise<void> {
    await this.refreshData(1, true)
  }

  private async refreshDeps(): Promise<void> {
    try {
      if (this.adminState.activeProjectId <= 0) {
        return
      }
      const deps = await statementOfWorksService.getEditorDependencies(this.adminState.activeProjectId)
      this.allWbsCategories = deps.wbsCategories
      this.wbss = deps.wbs
      this.updateAvailableRoles()
    } catch (err: any) {
      this.toastState.showError(err.toString())
    }
  }

  private updateAvailableRoles(): void {
    // TODO: Refactor this once users and roles are connected.
    this.availableWbsCategories = this.allWbsCategories.concat([])
    if (this.availableWbsCategories.length === 0) {
      this.toastState.showInfo('There are no available WBS categories.')
    }
  }

  async onPageChange(page: number): Promise<void> {
    this.page = page
    await this.refreshData()
  }

  public async refreshData(pageNum?: number, includeDeps = false): Promise<void> {
    try {
      this.loading = true
      if (includeDeps) {
        await this.refreshDeps()
      }
      if (pageNum) {
        this.page = pageNum
      }
      if (this.adminState.activeProjectId <= 0) {
        return
      }
      const catFilter = this.wbsCatFilterId > 0 ? this.wbsCatFilterId : undefined
      this.sows = await statementOfWorksService.getStatementOfWorks(this.adminState.activeProjectId, this.pageSize, this.page, this.claimsFilter || undefined, catFilter)
      this.buildSowMappings()
    } catch (err: any) {
      this.toastState.showError(err.toString())
    } finally {
      this.loading = false
    }
  }

  buildSowMappings(): void {
    this.sowMappings = []
    for (const sow of this.sows.data) {
      const sm: SowClaimMappingDto = { sow: sow, wbsCategories: [] }
      for (const wbsCat of this.availableWbsCategories) {
        let claimedIndex = -1
        if (sow.projectWorkBreakdownStructureCategories) {
          claimedIndex = sow.projectWorkBreakdownStructureCategories.findIndex((pwc: ProjectWorkBreakdownStructureCategoryContract) => pwc.id === wbsCat.id)
        }
        const sowRoleMapping: SowClaimedWbsMapping = { statementOfWorkId: sow.id, projectWorkBreakdownStructureCategoryId: wbsCat.id, claimed: claimedIndex >= 0, projectWorkBreakdownStructureId: null, originalProjectWorkBreakdownStructureId: null }
        if (claimedIndex >= 0 && sow.projectWorkBreakdownStructureCategories) {
          sowRoleMapping.projectWorkBreakdownStructureId = sow.projectWorkBreakdownStructureCategories[claimedIndex].meta?.pivot_project_work_breakdown_structure_id || null

          if (sowRoleMapping.projectWorkBreakdownStructureId) {
            const wbs = this.wbss.find(wbs => wbs.id === sowRoleMapping.projectWorkBreakdownStructureId)
            sowRoleMapping.projectWorkBreakdownStructureText = wbs ? `${wbs.wbsNumber } - ${ wbs.description }` : ''
          } else {
            sowRoleMapping.projectWorkBreakdownStructureText = ''
          }

          sowRoleMapping.originalProjectWorkBreakdownStructureId = sowRoleMapping.projectWorkBreakdownStructureId
        }
        sm.wbsCategories.push(sowRoleMapping)
      }
      this.sowMappings.push(sm)
    }
  }

  doesSowHaveWbsCatClaim(sow: StatementOfWorkContract, wbsCat: ProjectWorkBreakdownStructureCategoryContract): boolean {
    if (!sow.projectWorkBreakdownStructureCategories) {
      return false
    }
    const hasRole = sow.projectWorkBreakdownStructureCategories.findIndex(x => x.id === wbsCat.id) >= 0
    return hasRole
  }

  async onClaimChange(event: Event, sow: StatementOfWorkContract, wbsCat: ProjectWorkBreakdownStructureCategoryContract): Promise<void> {
    const target = event?.target as HTMLInputElement
    if (!target) {
      console.error('No target on claim event')
      return
    }
    try {
      Vue.set(this.saving, sow.id, true)
      if (target.checked) {
        await statementOfWorksService.claimStatementOfWork(this.adminState.activeProjectId, sow.id, wbsCat.id)
      } else {
        await statementOfWorksService.unclaimStatementOfWork(this.adminState.activeProjectId, sow.id, wbsCat.id)
      }
      await this.refreshData()
      this.toastState.showSuccess('Statement of work claim successful')
    } catch (err: any) {
      console.error(err)
      this.toastState.showError('Statement of work claim failed.')
    } finally {
      Vue.set(this.saving, sow.id, false)
    }
  }

  async onMapWbs(projectWorkbreakdownStructureContract: ProjectWorkBreakdownStructureContract|null, mapping: SowClaimedWbsMapping): Promise<void> {
    try {
      this.savingMapping = true
      if (projectWorkbreakdownStructureContract?.id) {
        await statementOfWorksService.mapSowToWbs(this.adminState.activeProjectId, mapping.statementOfWorkId, mapping.projectWorkBreakdownStructureCategoryId, projectWorkbreakdownStructureContract.id)
        mapping.projectWorkBreakdownStructureId = projectWorkbreakdownStructureContract.id
        mapping.projectWorkBreakdownStructureText = `${projectWorkbreakdownStructureContract.wbsNumber } - ${ projectWorkbreakdownStructureContract.description }`
      } else {
        await statementOfWorksService.unmapSowFromWbs(this.adminState.activeProjectId, mapping.statementOfWorkId, mapping.projectWorkBreakdownStructureCategoryId, mapping.originalProjectWorkBreakdownStructureId)
        mapping.projectWorkBreakdownStructureId = null
        mapping.projectWorkBreakdownStructureText = ''
      }
      this.toastState.showSuccess('Statement of work mapping successful')
    } catch (err: any) {
      console.error(err)
      this.toastState.showError('Statement of work mapping failed')
    } finally {
      this.savingMapping = false
    }
  }

  async setClaimsFilter(value: ClaimFilterType | null): Promise<void> {
    this.claimsFilter = value
    if (value !== ClaimFilterType.mapped) {
      this.wbsCatFilterId = 0
    }
    await this.refreshData(1) // if filter changes, need to reset page to 1.
  }
}
