




import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'

@Component({})
export default class AirDatePicker extends Vue {

  @Prop({})
  public value?: string|Date

  @Prop({ default: false })
  public dataAsISO8601!: boolean

  @Prop({ default: localeEn.dateFormat, type: String })
  public dateFormat!: string

  @Prop({ default: true, type: Boolean })
  public autoClose!: boolean

  @Prop()
  public minDate?: string|Date

  @Prop()
  public maxDate?: string|Date

  @Ref('datePickerEl')
  public datePickerEl!: HTMLInputElement

  public datePicker?: AirDatepicker

  mounted(): void {
    this.datePicker = new AirDatepicker(this.datePickerEl, {
      autoClose: this.autoClose,
      dateFormat: this.dateFormat,
      locale: localeEn,
      selectedDates: this.value ? [this.value] : [],
      minDate: this.minDate,
      maxDate: this.maxDate,
      onSelect: ({ date }) => {
        const dt = date as Date
        // const utc = new Date(dt.getTime() - dt.getTimezoneOffset()*60000)
        this.$emit('input', this.dataAsISO8601 ? dt.toISOString() : dt)
        this.$emit('change')
      }
    })
  }

  @Watch('minDate')
  onMinDateChange(): void {
    this.datePicker?.update({
      minDate: this.minDate
    })
  }

  @Watch('maxDate')
  onMaxDateChange(): void {
    this.datePicker?.update({
      maxDate: this.maxDate
    })
  }

  preventManual(evt: Event): boolean {
    evt.preventDefault()
    return false
  }
}
