var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sow-types-container" },
    [
      _c("editable-list", {
        attrs: {
          dataModelUri: "statement-of-work-types",
          uri: "sow-types",
          listTitle:
            "SOW Types for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          sortProperty: "sowTypeName",
          dataModelProjectBased: true
        },
        on: { onAddNew: _vm.onAddNew },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("SOW Type Name")
                ]),
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("Acronym")]),
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("Description")
                ]),
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("Claimable")])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c("div", { staticClass: "col" }, [
                        !slotProps.isEditing
                          ? _c(
                              "span",
                              { staticClass: "prop-display normalized-value" },
                              [_vm._v(_vm._s(slotProps.data.sowTypeName))]
                            )
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.sowTypeName,
                                  expression: "slotProps.data.sowTypeName"
                                }
                              ],
                              class: [
                                "form-control",
                                "form-control-sm",
                                "me-1",
                                {
                                  "is-invalid": slotProps.getValidationError(
                                    "sowTypeName"
                                  )
                                }
                              ],
                              attrs: { type: "text", required: "" },
                              domProps: { value: slotProps.data.sowTypeName },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "sowTypeName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        slotProps.getValidationError("sowTypeName")
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                _vm._s(
                                  slotProps.getValidationError("sowTypeName")
                                    .message
                                )
                              )
                            ])
                          : _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                      ]),
                      _c("div", { staticClass: "col" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.acronym))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.acronym,
                                  expression: "slotProps.data.acronym"
                                }
                              ],
                              class: [
                                "form-control",
                                "form-control-sm",
                                "me-1",
                                {
                                  "is-invalid": slotProps.getValidationError(
                                    "acronym"
                                  )
                                }
                              ],
                              attrs: { type: "text", required: "" },
                              domProps: { value: slotProps.data.acronym },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "acronym",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        slotProps.getValidationError("acronym")
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                _vm._s(
                                  slotProps.getValidationError("acronym")
                                    .message
                                )
                              )
                            ])
                          : _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                      ]),
                      _c("div", { staticClass: "col" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.description))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.description,
                                  expression: "slotProps.data.description"
                                }
                              ],
                              staticClass: "form-control form-control-sm me-1",
                              attrs: { type: "text" },
                              domProps: { value: slotProps.data.description },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("This field is required.")
                        ])
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c("span", { staticClass: "prop-display" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: slotProps.data.claimable,
                                expression: "slotProps.data.claimable"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: slotProps.data.id + "-claimable",
                              disabled: !slotProps.isEditing
                            },
                            domProps: {
                              checked: Array.isArray(slotProps.data.claimable)
                                ? _vm._i(slotProps.data.claimable, null) > -1
                                : slotProps.data.claimable
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = slotProps.data.claimable,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          slotProps.data,
                                          "claimable",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          slotProps.data,
                                          "claimable",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(slotProps.data, "claimable", $$c)
                                  }
                                },
                                function($event) {
                                  return _vm.rowState.setIsDirty(true)
                                }
                              ]
                            }
                          })
                        ])
                      ])
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }