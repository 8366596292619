var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editable-list", {
    attrs: {
      dataModelUri: _vm.dataModelUri,
      uri: "functional-areas",
      sortProperty: "functionalArea",
      listTitle:
        "Functional Areas for " +
        (_vm.isProjectLevel
          ? _vm.adminState.activeProjectName
          : _vm.adminState.activeCompanyName),
      dataModelProjectBased: _vm.isProjectLevel
    },
    scopedSlots: _vm._u([
      {
        key: "row-header-content",
        fn: function() {
          return [
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("ID")]),
            _c("div", { staticClass: "col fw-bold" }, [
              _vm._v("Functional Area")
            ])
          ]
        },
        proxy: true
      },
      {
        key: "row-content",
        fn: function(slotProps) {
          return [
            !slotProps.loading
              ? [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(_vm._s(slotProps.data.id))
                  ]),
                  _c("div", { staticClass: "col input-group has-validation" }, [
                    !slotProps.isEditing
                      ? _c(
                          "span",
                          { staticClass: "prop-display normalized-value" },
                          [_vm._v(_vm._s(slotProps.data.functionalArea))]
                        )
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: slotProps.data.functionalArea,
                              expression: "slotProps.data.functionalArea"
                            }
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "text", required: "" },
                          domProps: { value: slotProps.data.functionalArea },
                          on: {
                            change: function($event) {
                              return slotProps.setDirty()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                slotProps.data,
                                "functionalArea",
                                $event.target.value
                              )
                            }
                          }
                        }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("This field is required.")
                    ])
                  ])
                ]
              : [
                  _c("span", { staticClass: "placeholder col" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col" })
                ]
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }