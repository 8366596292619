

































import { Component, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'

@Component({
  components: {
    EditableList,
  }
})
export default class ContractDataRequestLists extends Vue {

  toastState: ToastState = getModule(ToastState)
  adminState: AdminState = getModule(AdminState)

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onAddNew(event: any) {
    event.newData.projectId = this.adminState.activeProjectId
  }
}
