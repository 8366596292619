









































import exportService from '@/services/export-service'
import uploadService from '@/services/upload-service'
import { UploadEndpointContract } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tooltip } from 'bootstrap'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'

@Component({})
export default class ImportExportRow extends Vue {

  @Prop({ required: true })
  public data!: UploadEndpointContract

  @Prop({ default: false })
  public isSupport!: boolean

  @Prop({ default: false })
  public isProjectLevel!: boolean

  private descTooltip?: Tooltip
  toastState: ToastState = getModule(ToastState)
  adminState: AdminState = getModule(AdminState)

  get disableActions(): boolean {
    return this.data.projectBased && !this.adminState.activeProjectId
  }

  async mounted(): Promise<void> {
    const el = document.getElementById(`desc-tooltip-${this.data.id}`)
    if (el) {
      this.descTooltip = new Tooltip(el, { placement: 'right' })
    }
  }

  public async processUpload(): Promise<void> {
    const importFileCtrl = this.$refs.importFile
    if (importFileCtrl) {
      // @ts-ignore
      const file = importFileCtrl.files[0]
      if (file) {
        try {
          let bodyData
          if (this.data.projectBased) {
            bodyData = { projectId: this.adminState.activeProjectId }
          }
          const result = await uploadService.uploadFile(this.data, file, bodyData)
          console.log('file upload result', result)
          this.toastState.showSuccess('File uploaded successfully')
          this.$emit('fileUploaded', result)
          // @ts-ignore
          importFileCtrl.value = ''
        } catch (err: any) {
          this.toastState.showError(err.message || 'File upload failed')
        }
      }
    }
  }

  public submit(e: Event): void {
    e.preventDefault()
    this.processUpload()
  }

  public exportData(type: 'xlsx'|'csv'): void {
    const projectId = this.isProjectLevel ? this.adminState.activeProjectId : 'company'
    exportService.exportEndpointData(this.data, type, projectId)
  }

  public downloadTemplate(type: 'xlsx'|'csv'): void {
    const projectId = this.isProjectLevel ? this.adminState.activeProjectId : 'company'
    exportService.downloadImportTemplate(this.data, type, projectId)
  }
}
