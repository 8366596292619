import { BaseCostCenterTypeContract, ContractLineItemNumberTypeContract, CostCenterContract, CostCenterTypeContract, FunctionalAreaContract, OptionPeriodContract, ProjectCostCenterContract, ProjectCostCenterTypeContract, ProjectFunctionalAreaContract, ProjectRoleContract, ProjectWorkBreakdownStructureCategoryContract, ProjectWorkBreakdownStructureContract, UserContract, WorkBreakdownStructureCategoryContract, WorkBreakdownStructureContract } from './dtos'

export interface ApiError {
  code: string;
  message: string;
  messages?: ApiValidationErrors
  status: number;
  stack?: string;
}

export interface ApiResponse<T> {
  success: boolean;
  error?: ApiError;
  response?: T;
}

export interface UploadEndpointContract {
  id: number
  createdAt: Date
  updatedAt: Date
  uri: string
  endpointName: string
  filenameFormat: string | null
  overwrite: boolean
  maxFileSizeBytes: number
  accept: string | null
  multipleFilesEnabled: boolean
  storageType: 'local' | 's3'
  storagePath: string
  autoExpirationEnabled: boolean
  autoExpirationSeconds: number | null
  completionEventName: string | null
  importPriority: number | null
  dependencyDescription: string | null
  importSet?: ImportSetContract
  parentUploadEndpointId?: number
  projectBased: boolean
}

export type FileUploadError = {
  fieldName: string;
  clientName: string;
  message: string;
  type: 'size' | 'extname' | 'fatal';
}

export interface FileUploadResultContract {
  result: 'success' | 'failed'
  originalFilename: string
  destinationFilename: string
  size: number
  errors?: FileUploadError[]
  expiration?: string
  id: number
}

export interface ImportSetContract {
  id: number
  createdAt: Date
  updatedAt: Date
  name: string
  priority: number
}

export interface ImportSetUIData {
  name: string
  priority: number
  endpointMap: {
    primary: UploadEndpointContract
    support: UploadEndpointContract[]
  }[]
}

export enum ResourceType {
  Hours = 'time',
  Money = 'money'
}

export interface PaginationMetaData {
  total: number
  totalPages: number
  perPage: number
  currentPage: number
  lastPage: number
  firstPage: number
  firstPageUrl: string | null
  lastPageUrl: string | null
  nextPageUrl: string | null
  previousPageUrl: string | null
}

export interface PaginationResult<T> {
  meta: PaginationMetaData
  data: T[]
}

export interface EditableRowContract {
  isEditing: boolean
  isSaving: boolean
  isDeleting: boolean
  actionComplete: (actionName: string, errorMessage?: string, errors?: ValidationError[]) => void
}

export interface CrudSaveEvent {
  data: any
  component: EditableRowContract
}

export interface EditableListSearchParams {
  [index: string]: string|number|boolean|Date
}

export interface CostCenterEditorDependencies {
  costCenterTypes: BaseCostCenterTypeContract[] // CostCenterTypeContract[]|ProjectCostCenterTypeContract[]
  functionalAreas: FunctionalAreaContract[]|ProjectFunctionalAreaContract[]
  costCenters: CostCenterContract[]|ProjectCostCenterContract[]
}

export interface CostCenterManagementDto {
  id: number
  createdAt?: Date
  updatedAt?: Date
  projectId?: number
  costCenterNumber?: string
  name?: string
  costCenterTypeId?: number
  functionalAreaId?: number
  isNew?: boolean
}

export interface WbsManagementDependencies {
  wbsCategories: WorkBreakdownStructureCategoryContract[]
  users: UserContract[]
}

export interface SowClaimsMappingDepenendencies {
  wbsCategories: ProjectWorkBreakdownStructureCategoryContract[]
  wbs: ProjectWorkBreakdownStructureContract[]
}

export interface OrderUpdatedEvent {
  data: any[],
  done: () => void
}

export interface ToastData {
  id: string
  content: string
  type: 'success'|'danger'|'info'
  delay?: number
}

export interface ClinManagementDto {
  id: number
  createdAt?: Date
  updatedAt?: Date
  contractLineItemNumber: string
  description: string
  contractLineItemNumberTypeId: number
  contractLineItemNumberTypeName: string
  projectId: number
  optionPeriods?: { id: number, name: string, enabled: boolean }[]
  isNew?: boolean
}

export interface ClinManagementEditorDependencies {
  optionPeriods: OptionPeriodContract[]
  clinTypes: ContractLineItemNumberTypeContract[]
}

export interface ApiValidationErrors {
  errors: {
    rule: string
    field: string
    message: string }[]
}

export interface ValidationError {
  field: string
  message: string
}
