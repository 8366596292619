












































import { Component, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import AirDatePicker from '@/components/util/AirDatePicker.vue'
import AdminState from '@/store/modules/admin'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    AirDatePicker,
    EditableList,
  }
})
export default class OptionPeriods extends Vue {

  adminState: AdminState = getModule(AdminState)

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onAddNew(event: any) {
    event.newData.projectId = this.adminState.activeProjectId
  }
}
