import { BaseHttpService } from './base-http-service'
import { BoeBokEstimateContract } from '@/types/dtos'

class BoeBokEstimateService extends BaseHttpService {
    public async createBoeBokEstimate(boeBokEstimate: Partial<BoeBokEstimateContract>): Promise<BoeBokEstimateContract> {
        const uri = '/api/boe-bok-estimates'
        const response = await this.doApiPost<BoeBokEstimateContract>(uri, JSON.stringify({ data: boeBokEstimate }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async updateBoeBokEstimate(id: number, boeBokEstimate: Partial<BoeBokEstimateContract>): Promise<BoeBokEstimateContract> {
        const uri = `/api/boe-bok-estimates/${id}`
        const response = await this.doApiPut<BoeBokEstimateContract>(uri, JSON.stringify({ data: boeBokEstimate }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async deleteBoeBokEstimate(id: number): Promise<boolean> {
        const uri = `/api/boe-bok-estimates/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }
}

export default new BoeBokEstimateService()
