var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "work-breakdown-structure-estimation-data-container px-1" },
    [
      _c("h5", [
        _vm._v(
          _vm._s(
            _vm.projectEstimationState.workBreakdownStructureEstimationData
              .wbsNumber
          ) +
            " " +
            _vm._s(
              _vm.projectEstimationState.workBreakdownStructureEstimationData
                .description
            )
        )
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-stretch justify-content-between mb-1"
        },
        [
          _c("div", { staticClass: "row align-items-stretch flex-fill" }, [
            _c("div", { staticClass: "col d-flex align-items-center" }, [
              _c(
                "span",
                {
                  staticClass: "fw-bold",
                  staticStyle: { "padding-right": "5px" }
                },
                [_vm._v("Category:")]
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("startCase")(
                      _vm.projectEstimationState
                        .workBreakdownStructureEstimationData
                        .workBreakdownStructureCategory.categoryName
                    )
                  ) +
                  " "
              )
            ]),
            _c("div", { staticClass: "col d-flex align-items-center" }, [
              _c(
                "span",
                {
                  staticClass: "fw-bold",
                  staticStyle: { "padding-right": "5px" }
                },
                [_vm._v("Status:")]
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.status
                  ) +
                  " "
              )
            ]),
            _c("div", { staticClass: "col d-flex align-items-center" }, [
              _c(
                "span",
                {
                  staticClass: "fw-bold",
                  staticStyle: { "padding-right": "5px" }
                },
                [_vm._v("WBS Owner:")]
              ),
              _vm._v(" " + _vm._s(_vm.getResponsibleOwnerName()) + " ")
            ]),
            _c(
              "div",
              {
                staticClass: "col d-flex align-items-center justify-content-end"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: {
                          color: "white",
                          "text-decoration": "none"
                        },
                        attrs: {
                          to:
                            "/project-admin/estimator-sow-reference?projectId=" +
                            _vm.adminState.activeProjectId +
                            "&projectWorkBreakdownStructureId=" +
                            _vm.projectEstimationState.workBreakdownStructureId,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Work Reference ")]
                    )
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary ms-1",
                    attrs: { type: "button" }
                  },
                  [_vm._v("Approve Estimate")]
                )
              ]
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex align-items-stretch justify-content-between" },
        [
          _c("div", { staticClass: "row align-items-stretch flex-fill" }, [
            _c(
              "div",
              { staticClass: "col-6 d-flex align-items-center fw-bold" },
              [_vm._v(" Task Description ")]
            ),
            _c(
              "div",
              { staticClass: "col-4 d-flex align-items-center fw-bold" },
              [_vm._v(" Deliverables ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "col d-flex align-items-center justify-content-end mb-1"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    staticStyle: { "margin-right": "3px" },
                    attrs: {
                      disabled: !_vm.wbsDataChanged,
                      title: "Save",
                      "data-bs-toggle": "tooltip"
                    },
                    on: {
                      click: function($event) {
                        return _vm.updateWbsData()
                      }
                    }
                  },
                  [_c("i", { staticClass: "bi-save" })]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: {
                      disabled: !_vm.wbsDataChanged,
                      title: "Save",
                      "data-bs-toggle": "tooltip"
                    },
                    on: {
                      click: function($event) {
                        return _vm.revertWbsData()
                      }
                    }
                  },
                  [_c("i", { staticClass: "bi-arrow-counterclockwise" })]
                )
              ]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-stretch justify-content-between",
          staticStyle: { "margin-bottom": "10px" }
        },
        [
          _c("div", { staticClass: "row align-items-stretch flex-fill" }, [
            _c("div", { staticClass: "col-6 d-flex align-items-center" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.projectEstimationState
                        .workBreakdownStructureEstimationData.taskDescription,
                    expression:
                      "projectEstimationState.workBreakdownStructureEstimationData.taskDescription"
                  }
                ],
                staticClass: "form-control form-control-sm",
                staticStyle: { width: "100%" },
                attrs: { rows: "2" },
                domProps: {
                  value:
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.taskDescription
                },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.projectEstimationState
                          .workBreakdownStructureEstimationData,
                        "taskDescription",
                        $event.target.value
                      )
                    },
                    function($event) {
                      _vm.wbsDataChanged = true
                    }
                  ]
                }
              })
            ]),
            _c("div", { staticClass: "col-6 d-flex align-items-center" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.projectEstimationState
                        .workBreakdownStructureEstimationData.deliverables,
                    expression:
                      "projectEstimationState.workBreakdownStructureEstimationData.deliverables"
                  }
                ],
                staticClass: "form-control form-control-sm",
                staticStyle: { width: "100%" },
                attrs: { rows: "2" },
                domProps: {
                  value:
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.deliverables
                },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.projectEstimationState
                          .workBreakdownStructureEstimationData,
                        "deliverables",
                        $event.target.value
                      )
                    },
                    function($event) {
                      _vm.wbsDataChanged = true
                    }
                  ]
                }
              })
            ])
          ])
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-stretch justify-content-between",
          staticStyle: { "margin-bottom": "10px" }
        },
        [
          _c("div", { staticClass: "row align-items-stretch flex-fill" }, [
            _c("div", { staticClass: "col-6 d-flex align-items-center" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.projectEstimationState
                        .workBreakdownStructureEstimationData.groundRules,
                    expression:
                      "projectEstimationState.workBreakdownStructureEstimationData.groundRules"
                  }
                ],
                staticClass: "form-control form-control-sm",
                staticStyle: { width: "100%" },
                attrs: { rows: "2" },
                domProps: {
                  value:
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.groundRules
                },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.projectEstimationState
                          .workBreakdownStructureEstimationData,
                        "groundRules",
                        $event.target.value
                      )
                    },
                    function($event) {
                      _vm.wbsDataChanged = true
                    }
                  ]
                }
              })
            ]),
            _c("div", { staticClass: "col-6 d-flex align-items-center" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.projectEstimationState
                        .workBreakdownStructureEstimationData.laborEstimates,
                    expression:
                      "projectEstimationState.workBreakdownStructureEstimationData.laborEstimates"
                  }
                ],
                staticClass: "form-control form-control-sm",
                staticStyle: { width: "100%" },
                attrs: { rows: "2" },
                domProps: {
                  value:
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.laborEstimates
                },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.projectEstimationState
                          .workBreakdownStructureEstimationData,
                        "laborEstimates",
                        $event.target.value
                      )
                    },
                    function($event) {
                      _vm.wbsDataChanged = true
                    }
                  ]
                }
              })
            ])
          ])
        ]
      ),
      Object.keys(_vm.projectEstimationState.optionPeriods).length
        ? _c("div", [
            _c(
              "ul",
              {
                staticClass: "nav nav-tabs",
                staticStyle: { "margin-bottom": "20px" }
              },
              _vm._l(
                Object.keys(_vm.projectEstimationState.optionPeriods),
                function(key, index) {
                  return _c(
                    "li",
                    {
                      key: key,
                      staticClass: "nav-item",
                      attrs: { role: "presentation" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAll()
                        }
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "nav-link fw-bold",
                          class: { active: index === 0 },
                          attrs: {
                            id:
                              "option-period-" +
                              _vm.projectEstimationState.optionPeriods[key]
                                .optionPeriod.id +
                              "-tab",
                            "data-bs-toggle": "tab",
                            "data-bs-target":
                              "#option-period-" +
                              _vm.projectEstimationState.optionPeriods[key]
                                .optionPeriod.id,
                            type: "button",
                            role: "tab",
                            "aria-controls":
                              "option-period-" +
                              _vm.projectEstimationState.optionPeriods[key]
                                .optionPeriod.id,
                            "aria-selected": "true"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.projectEstimationState.optionPeriods[key]
                                  .optionPeriod.optionPeriod
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                }
              ),
              0
            ),
            _c(
              "div",
              {
                staticClass: "tab-content",
                attrs: { id: "optionPeriodTabContent" }
              },
              _vm._l(
                Object.keys(_vm.projectEstimationState.optionPeriods),
                function(key, index) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "tab-pane fade",
                      class: { show: index === 0, active: index === 0 },
                      attrs: {
                        id:
                          "option-period-" +
                          _vm.projectEstimationState.optionPeriods[key]
                            .optionPeriod.id,
                        role: "tabpanel",
                        "aria-labelledby":
                          "option-period-" +
                          _vm.projectEstimationState.optionPeriods[key]
                            .optionPeriod.id
                      }
                    },
                    [
                      _c(
                        "section",
                        {
                          staticClass: "editable-row-container",
                          staticStyle: { "margin-bottom": "20px" }
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass:
                                "d-flex justify-content-start align-items-center placeholder-glow editable-row",
                              attrs: { novalidate: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row g-1 row-data align-items-center"
                                },
                                [
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("CLIN No.")
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.projectEstimationState
                                          .optionPeriods[key]
                                          .contractLineItemNumbers[0]
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("SOW Para Number(s)")
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.projectEstimationState.optionPeriods[
                                          key
                                        ].statementOfWorks.join(", ")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "form",
                            {
                              staticClass:
                                "d-flex justify-content-start align-items-center placeholder-glow editable-row",
                              attrs: { novalidate: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row g-1 row-data align-items-center"
                                },
                                [
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("OP Start")
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.projectEstimationState
                                            .optionPeriods[key].optionPeriod
                                            .startDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("OP End")
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.projectEstimationState
                                            .optionPeriods[key].optionPeriod
                                            .endDate
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "form",
                            {
                              staticClass:
                                "d-flex justify-content-start align-items-center placeholder-glow editable-row",
                              attrs: { novalidate: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row g-1 row-data align-items-center"
                                },
                                [
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("CLIN PoP Start")
                                  ]),
                                  _vm.projectEstimationState.optionPeriods[key]
                                    .clinPoPStart
                                    ? _c("div", { staticClass: "col-3" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.projectEstimationState
                                                .optionPeriods[key].clinPoPStart
                                            )
                                          )
                                        )
                                      ])
                                    : _c("div", { staticClass: "col-3" }, [
                                        _vm._v("Not Set")
                                      ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _vm._v("CLIN PoP End")
                                  ]),
                                  _vm.projectEstimationState.optionPeriods[key]
                                    .clinPoPEnd
                                    ? _c("div", { staticClass: "col-3" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.projectEstimationState
                                                .optionPeriods[key].clinPoPEnd
                                            )
                                          )
                                        )
                                      ])
                                    : _c("div", { staticClass: "col-3" }, [
                                        _vm._v("Not Set")
                                      ])
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "nav nav-tabs",
                          staticStyle: { "margin-bottom": "20px" }
                        },
                        _vm._l(
                          _vm.projectEstimationState.optionPeriods[key]
                            .costCenters,
                          function(costCenter, ccIndex) {
                            return _c(
                              "li",
                              {
                                key:
                                  _vm.projectEstimationState.optionPeriods[key]
                                    .optionPeriod.id +
                                  "-" +
                                  costCenter.costCenter.id,
                                staticClass: "nav-item",
                                attrs: { role: "presentation" },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelAll()
                                  }
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link fw-bold",
                                    class: { active: ccIndex === 0 },
                                    attrs: {
                                      id:
                                        "cost-center-" +
                                        _vm.projectEstimationState
                                          .optionPeriods[key].optionPeriod.id +
                                        "-" +
                                        costCenter.costCenter.id +
                                        "-tab",
                                      "data-bs-toggle": "tab",
                                      "data-bs-target":
                                        "#cost-center-" +
                                        _vm.projectEstimationState
                                          .optionPeriods[key].optionPeriod.id +
                                        "-" +
                                        costCenter.costCenter.id,
                                      type: "button",
                                      role: "tab",
                                      "aria-controls":
                                        "cost-center-" +
                                        _vm.projectEstimationState
                                          .optionPeriods[key].optionPeriod.id +
                                        "-" +
                                        costCenter.costCenter.id,
                                      "aria-selected": "true"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(costCenter.costCenter.name) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-content",
                          attrs: { id: "costCenterTabContent" }
                        },
                        _vm._l(
                          _vm.projectEstimationState.optionPeriods[key]
                            .costCenters,
                          function(costCenter, ccIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  _vm.projectEstimationState.optionPeriods[key]
                                    .optionPeriod.id +
                                  "-" +
                                  costCenter.costCenter.id,
                                staticClass: "tab-pane fade",
                                class: {
                                  show: ccIndex === 0,
                                  active: ccIndex === 0
                                },
                                attrs: {
                                  id:
                                    "cost-center-" +
                                    _vm.projectEstimationState.optionPeriods[
                                      key
                                    ].optionPeriod.id +
                                    "-" +
                                    costCenter.costCenter.id,
                                  role: "tabpanel",
                                  "aria-labelledby":
                                    "cost-center-" +
                                    _vm.projectEstimationState.optionPeriods[
                                      key
                                    ].optionPeriod.id +
                                    "-" +
                                    costCenter.costCenter.id
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row align-items-stretch",
                                    staticStyle: { "margin-bottom": "20px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-auto fw-bold d-flex align-items-center"
                                      },
                                      [_vm._v(" CC PoP Start: ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-2 d-flex align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-group has-validation"
                                          },
                                          [
                                            _c("air-date-picker", {
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                id:
                                                  "start-date-" +
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.id +
                                                  "-" +
                                                  costCenter.costCenter.id,
                                                dataAsISO8601: true,
                                                dateFormat: "MMMM dd, yyyy",
                                                minDate:
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.startDate,
                                                maxDate:
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.endDate,
                                                disabled:
                                                  costCenter.boeBok.id !== -1
                                              },
                                              model: {
                                                value:
                                                  costCenter.boeBok
                                                    .periodOfPerformanceStart,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    costCenter.boeBok,
                                                    "periodOfPerformanceStart",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "costCenter.boeBok.periodOfPerformanceStart"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback"
                                              },
                                              [
                                                _vm._v(
                                                  "This field is required."
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-auto fw-bold d-flex align-items-center"
                                      },
                                      [_vm._v(" CC PoP End: ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-2 d-flex align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-group has-validation"
                                          },
                                          [
                                            _c("air-date-picker", {
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                id:
                                                  "end-date-" +
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.id +
                                                  "-" +
                                                  costCenter.costCenter.id,
                                                dataAsISO8601: true,
                                                dateFormat: "MMMM dd, yyyy",
                                                minDate:
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.startDate,
                                                maxDate:
                                                  _vm.projectEstimationState
                                                    .optionPeriods[key]
                                                    .optionPeriod.endDate,
                                                disabled:
                                                  costCenter.boeBok.id !== -1
                                              },
                                              model: {
                                                value:
                                                  costCenter.boeBok
                                                    .periodOfPerformanceEnd,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    costCenter.boeBok,
                                                    "periodOfPerformanceEnd",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "costCenter.boeBok.periodOfPerformanceEnd"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback"
                                              },
                                              [
                                                _vm._v(
                                                  "This field is required."
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-auto d-flex align-items-center"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-primary",
                                            attrs: {
                                              disabled:
                                                costCenter.boeBok.id !== -1 ||
                                                !costCenter.boeBok
                                                  .periodOfPerformanceStart ||
                                                !costCenter.boeBok
                                                  .periodOfPerformanceEnd,
                                              title: "Save",
                                              "data-bs-toggle": "tooltip"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.saveBoeBok(
                                                  key,
                                                  costCenter.boeBok
                                                )
                                              }
                                            }
                                          },
                                          [_c("i", { staticClass: "bi-save" })]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                costCenter.boeBok.id !== -1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row align-items-stretch d-flex align-items-center",
                                        staticStyle: { "margin-bottom": "20px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-auto fw-bold d-flex align-items-center"
                                          },
                                          [_vm._v(" Resource: ")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-3 d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedResourceId,
                                                    expression:
                                                      "selectedResourceId"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control form-control-sm",
                                                staticStyle: { width: "100%" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.selectedResourceId = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: -1 } },
                                                  [_vm._v("Select Resource")]
                                                ),
                                                _vm._l(
                                                  _vm.getCostCenterResources(
                                                    costCenter
                                                  ),
                                                  function(resource) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: resource.id,
                                                        domProps: {
                                                          value: resource.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("startCase")(
                                                              resource.resourceName
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-3 d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group has-validation"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.estimatingRationale,
                                                      expression:
                                                        "estimatingRationale"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "Enter Estimation Rationale"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.estimatingRationale
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.estimatingRationale =
                                                        $event.target.value
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "This field is required."
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-auto d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-primary",
                                                attrs: {
                                                  disabled:
                                                    _vm.selectedResourceId ===
                                                      -1 ||
                                                    !_vm.estimatingRationale,
                                                  title: "Add",
                                                  "data-bs-toggle": "tooltip"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.saveNewBoeBokEstimate(
                                                      costCenter.boeBok
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi-plus"
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                costCenter.boeBok.id !== -1 &&
                                costCenter.boeBok.boeBokEstimates &&
                                costCenter.boeBok.boeBokEstimates.length
                                  ? _c(
                                      "section",
                                      { staticClass: "resource-editor" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table boe-bok-month-table"
                                          },
                                          [
                                            _c(
                                              "thead",
                                              { staticClass: "table-light" },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticClass: "boepro-light"
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass: "icon-col"
                                                      },
                                                      [_vm._v(" ")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "sticky-col text-col"
                                                      },
                                                      [_vm._v("resource")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass: "text-col"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "estimating rationale"
                                                        )
                                                      ]
                                                    ),
                                                    _c("th", {}, [
                                                      _vm._v("row total")
                                                    ]),
                                                    _vm._l(
                                                      _vm.getEstimateMonthStrings(
                                                        costCenter.boeBok
                                                          .boeBokEstimates[0]
                                                      ),
                                                      function(
                                                        boeBokEstimateMonthString
                                                      ) {
                                                        return _c(
                                                          "th",
                                                          {
                                                            key: boeBokEstimateMonthString
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                boeBokEstimateMonthString
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "sticky-col-right"
                                                      },
                                                      [_vm._v(" ")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                costCenter.boeBok
                                                  .boeBokEstimates,
                                                function(
                                                  boeBokEstimate,
                                                  boeBEIndex
                                                ) {
                                                  return _c(
                                                    "tr",
                                                    { key: boeBokEstimate.id },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "icon-col"
                                                        },
                                                        [
                                                          _c(
                                                            "transition",
                                                            {
                                                              attrs: {
                                                                name: "flip",
                                                                mode: "out-in"
                                                              }
                                                            },
                                                            [
                                                              _vm.editingIndex !==
                                                              boeBEIndex
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      key:
                                                                        "edit",
                                                                      staticClass:
                                                                        "btn btn-sm btn-primary",
                                                                      staticStyle: {
                                                                        "margin-right":
                                                                          "3px"
                                                                      },
                                                                      attrs: {
                                                                        title:
                                                                          "Edit",
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        type:
                                                                          "button"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editMonths(
                                                                            boeBEIndex,
                                                                            boeBokEstimate
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bi-pencil-fill"
                                                                      })
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "button",
                                                                    {
                                                                      key:
                                                                        "cancel",
                                                                      staticClass:
                                                                        "btn btn-sm btn-secondary",
                                                                      staticStyle: {
                                                                        "margin-right":
                                                                          "3px"
                                                                      },
                                                                      attrs: {
                                                                        title:
                                                                          "Cancel",
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        type:
                                                                          "button"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.cancelEditMonths()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bi-x-lg"
                                                                      })
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm.isRowEditing(
                                                        boeBEIndex
                                                      )
                                                        ? _c("td", [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .editingBoeBokEstimate
                                                                        .projectResourceId,
                                                                    expression:
                                                                      "editingBoeBokEstimate.projectResourceId"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control form-control-sm",
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm.editingBoeBokEstimate,
                                                                        "projectResourceId",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      _vm.monthsDataChanged = true
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.getCostCenterResources(
                                                                  costCenter,
                                                                  boeBokEstimate.projectResourceId
                                                                ),
                                                                function(
                                                                  resource
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key:
                                                                        resource.id,
                                                                      domProps: {
                                                                        value:
                                                                          resource.id
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "startCase"
                                                                          )(
                                                                            resource.resourceName
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ])
                                                        : _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "capitalize sticky-col text-col"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  boeBokEstimate
                                                                    .projectResource
                                                                    .resourceName
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                      _vm.isRowEditing(
                                                        boeBEIndex
                                                      )
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-col rationale-col"
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .editingBoeBokEstimate
                                                                          .estimatingRationale,
                                                                      expression:
                                                                        "editingBoeBokEstimate.estimatingRationale"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control form-control-sm",
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .editingBoeBokEstimate
                                                                        .estimatingRationale
                                                                  },
                                                                  on: {
                                                                    input: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          _vm.editingBoeBokEstimate,
                                                                          "estimatingRationale",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        _vm.monthsDataChanged = true
                                                                      }
                                                                    ]
                                                                  }
                                                                })
                                                              ]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getRowTotal(
                                                                    _vm.editingBoeBokEstimate
                                                                  )
                                                                )
                                                              )
                                                            ]),
                                                            _vm._l(
                                                              _vm
                                                                .editingBoeBokEstimate
                                                                .boeBokEstimateMonths,
                                                              function(
                                                                boeBokEstimateMonth
                                                              ) {
                                                                return _c(
                                                                  "td",
                                                                  {
                                                                    key:
                                                                      boeBokEstimateMonth.id
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model.number",
                                                                            value:
                                                                              boeBokEstimateMonth.estimateAmount,
                                                                            expression:
                                                                              "boeBokEstimateMonth.estimateAmount",
                                                                            modifiers: {
                                                                              number: true
                                                                            }
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control form-control-sm num-edit-input",
                                                                        attrs: {
                                                                          type:
                                                                            "number",
                                                                          min:
                                                                            "0",
                                                                          step:
                                                                            ".01"
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            boeBokEstimateMonth.estimateAmount
                                                                        },
                                                                        on: {
                                                                          input: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              ) {
                                                                                return
                                                                              }
                                                                              _vm.$set(
                                                                                boeBokEstimateMonth,
                                                                                "estimateAmount",
                                                                                _vm._n(
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              _vm.monthsDataChanged = true
                                                                            }
                                                                          ],
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.validateMonths(
                                                                              boeBokEstimateMonth,
                                                                              boeBokEstimate
                                                                                .projectResource
                                                                                .resourceType
                                                                            )
                                                                          },
                                                                          blur: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$forceUpdate()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ]
                                                        : [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-col rationale-col",
                                                                attrs: {
                                                                  title:
                                                                    boeBokEstimate.estimatingRationale
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      boeBokEstimate.estimatingRationale
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getRowTotal(
                                                                    boeBokEstimate
                                                                  )
                                                                )
                                                              )
                                                            ]),
                                                            _vm._l(
                                                              boeBokEstimate.boeBokEstimateMonths,
                                                              function(
                                                                boeBokEstimateMonth
                                                              ) {
                                                                return _c(
                                                                  "td",
                                                                  {
                                                                    key:
                                                                      boeBokEstimateMonth.id
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          boeBokEstimate
                                                                            .projectResource
                                                                            .resourceType ===
                                                                            "money"
                                                                            ? "$"
                                                                            : ""
                                                                        ) +
                                                                          _vm._s(
                                                                            boeBokEstimateMonth.estimateAmount
                                                                          )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "sticky-col-right"
                                                        },
                                                        [
                                                          _c(
                                                            "transition",
                                                            {
                                                              attrs: {
                                                                name: "flip",
                                                                mode: "out-in"
                                                              }
                                                            },
                                                            [
                                                              _vm.editingIndex !==
                                                              boeBEIndex
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      key:
                                                                        "delete",
                                                                      staticClass:
                                                                        "btn btn-sm btn-danger",
                                                                      attrs: {
                                                                        title:
                                                                          "Delete",
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        type:
                                                                          "button"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteBoeBokEstimate(
                                                                            boeBokEstimate.id
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bi-trash-fill"
                                                                      })
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "button",
                                                                    {
                                                                      key:
                                                                        "save",
                                                                      staticClass:
                                                                        "btn btn-sm btn-primary",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.monthsDataChanged ||
                                                                          !_vm
                                                                            .editingBoeBokEstimate
                                                                            .estimatingRationale,
                                                                        title:
                                                                          "Save",
                                                                        "data-bs-toggle":
                                                                          "tooltip",
                                                                        type:
                                                                          "button"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.updateBoeBokEstimateAndMonths(
                                                                            _vm.editingBoeBokEstimate
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bi-save"
                                                                      })
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }
                        ),
                        0
                      )
                    ]
                  )
                }
              ),
              0
            )
          ])
        : _vm._e(),
      _c("confirm-modal", {
        ref: "deleteModal",
        attrs: {
          header: "Confirm Delete",
          confirmText: "Delete",
          message: _vm.deleteConfirmationMessageText
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-stretch justify-content-between" },
      [
        _c("div", { staticClass: "row align-items-stretch flex-fill" }, [
          _c(
            "div",
            { staticClass: "col-6 d-flex align-items-center fw-bold" },
            [_vm._v(" Ground Rules, Assumptions, Exclusions, Limitations ")]
          ),
          _c(
            "div",
            { staticClass: "col-6 d-flex align-items-center fw-bold" },
            [_vm._v(" Labor Estimates - Methodology and Rationale ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }