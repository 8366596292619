


































import { Component } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import ProjectLevelAdminEditor from '../projects/ProjectLevelAdminEditor'

@Component({
  components: {
    EditableList,
  }
})
export default class WorkBreakdownStructureCategories extends ProjectLevelAdminEditor {
  get baseDataModelUri(): string {
    return 'work-breakdown-structure-categories'
  }
}
