

























































import { Component, Vue } from 'vue-property-decorator'
import AdminState from '@/store/modules/admin'
import ProjectEstimationState from '@/store/modules/project-estimation-state'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {

  }
})
export default class EstimatorSowReference extends Vue {
  adminState: AdminState = getModule(AdminState)
  projectEstimationState: ProjectEstimationState = getModule(ProjectEstimationState)

  async mounted(): Promise<void> {
    const query = new URLSearchParams(location.search)
    if (query.has('projectId')) {
      const projectId = Number(query.get('projectId'))
      if (!isNaN(projectId)) {
        this.adminState.selectProject(projectId)
        if (query.has('projectWorkBreakdownStructureId')) {
          const projectWorkBreakdownStructureId = Number(query.get('projectWorkBreakdownStructureId'))
          if (!isNaN(projectWorkBreakdownStructureId)) {
            this.projectEstimationState.setWorkBreakdownStructureId(projectWorkBreakdownStructureId)
            await this.projectEstimationState.loadWorkBreakdownEstimationStructureData()
            console.log(this.projectEstimationState.workBreakdownStructureEstimationData)
          }
        }
      }
    }
  }
}
