import { BaseHttpService } from './base-http-service'
import { EstimationDependency, ProjectWorkBreakdownStructureContract } from '@/types/dtos'

class ProjectWorkBreakdownStructureService extends BaseHttpService {
    public async getProjectWorkBreakdownStructure(id: number, withEstimatorDependencies = false) : Promise<ProjectWorkBreakdownStructureContract> {
        const uri = `/api/project-work-breakdown-structures/${id}?withEstimatorDependencies=${withEstimatorDependencies}`
        const response = await this.doApiGet<ProjectWorkBreakdownStructureContract>(uri)
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async getProjectWorkBreakdownStructures(projectId: number): Promise<ProjectWorkBreakdownStructureContract[]> {
        const uri = `/api/projects/${projectId}/work-breakdown-structures`
        const response = await this.doApiGet<ProjectWorkBreakdownStructureContract[]>(uri)
        if (response.success) {
                return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async updateProjectWorkBreakdownStructure(id: number, projectWorkBreakdownStructure: Partial<ProjectWorkBreakdownStructureContract>): Promise<ProjectWorkBreakdownStructureContract> {
        const uri = `/api/project-work-breakdown-structures/${id}`
        const response = await this.doApiPut<ProjectWorkBreakdownStructureContract>(uri, JSON.stringify({ data: projectWorkBreakdownStructure }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async getEstimationDependencies(projectId: number): Promise<EstimationDependency> {
        const uri = `/api/projects/${projectId}/editor-data/estimation`
        const response = await this.doApiGet<EstimationDependency>(uri)
        if (response.success) {
            return response.response!
        } else {
            this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
        }
    }
}

export default new ProjectWorkBreakdownStructureService()
