import { WbsManagementDependencies } from '@/types'
import { BaseHttpService } from './base-http-service'

class WorkBreakdownStructureService extends BaseHttpService {
  public async getEditorDependencies(projectId?: number): Promise<WbsManagementDependencies> {
    const uri = projectId ? `/api/projects/${projectId}/editor-data/work-breakdown-structures` : '/api/editor-data/work-breakdown-structures'
    const response = await this.doApiGet<WbsManagementDependencies>(uri)
    if (response.success) {
      return response.response!
    } else {
      this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
    }
  }

  public getEmptyEditorDependencies(): WbsManagementDependencies {
    return {
      wbsCategories: [],
      users: [],
    }
  }
}

export default new WorkBreakdownStructureService()
