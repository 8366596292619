var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projectEstimationState.workBreakdownStructureEstimationData
    ? _c("section", { staticClass: "estimator-sow-reference-container" }, [
        _c(
          "div",
          {
            staticClass: "d-flex justify-content-between align-items-center",
            staticStyle: { "margin-bottom": "25px" }
          },
          [
            _c("h5", [
              _vm._v(
                "WBS " +
                  _vm._s(
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.wbsNumber
                  ) +
                  " - " +
                  _vm._s(
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.description
                  )
              )
            ])
          ]
        ),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "25px" } },
          [
            _c("div", { staticClass: "col-6 d-flex justify-content-between" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData.wbsDictionary ||
                      "Not provided"
                  ) +
                  " "
              )
            ]),
            _c("div", { staticClass: "col-6 d-flex" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.projectEstimationState
                      .workBreakdownStructureEstimationData
                      .tailoredWbsDictionary || "Not provided"
                  ) +
                  " "
              )
            ])
          ]
        ),
        _vm._m(1),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("table", { staticClass: "table table-sm table-striped" }, [
              _vm._m(2),
              _c(
                "tbody",
                _vm._l(
                  _vm.projectEstimationState
                    .workBreakdownStructureEstimationData
                    .workBreakdownStructureCategory.statementOfWorks,
                  function(sow) {
                    return _c("tr", { key: sow.id }, [
                      _c("td", [_vm._v(" " + _vm._s(sow.sowNumber) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(sow.sowDescription) + " ")])
                    ])
                  }
                ),
                0
              )
            ])
          ])
        ])
      ])
    : _c("section", [_vm._m(3)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row fw-bold" }, [
      _c("div", { staticClass: "col-6 d-flex justify-content-between" }, [
        _vm._v(" Templated Dictionary ")
      ]),
      _c("div", { staticClass: "col-6 d-flex" }, [
        _vm._v(" Tailored Dictionary ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h5", [_vm._v("Work Definitions")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v(" SOW Number ")]),
      _c("th", [_vm._v(" Original ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h5", [_vm._v("Not a valid projectWorkBreakdownStructureId")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }