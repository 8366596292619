var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sow-claims-mapping" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h4", [
            _vm._v(
              "SOW Claim & Mapping for " +
                _vm._s(_vm.adminState.activeProjectName)
            )
          ]),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                attrs: { type: "button", disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.refreshData(_vm.page, true)
                  }
                }
              },
              [
                !_vm.loading
                  ? _c("i", { staticClass: "bi-arrow-clockwise" })
                  : _c(
                      "div",
                      {
                        staticClass: "spinner-border spinner-border-sm",
                        attrs: { role: "status" }
                      },
                      [
                        _c("span", { staticClass: "visually-hidden" }, [
                          _vm._v("Loading...")
                        ])
                      ]
                    ),
                _c("span", { staticClass: "ms-1" }, [_vm._v("Refresh")])
              ]
            )
          ])
        ]
      ),
      _c("section", { staticClass: "sow-row-container" }, [
        _c("ul", { staticClass: "nav nav-tabs align-items-center" }, [
          _vm._m(0),
          _vm._m(1),
          _c("li", { staticClass: "flex-grow-1 d-flex justify-content-end" }, [
            _vm.currentTab === "claims"
              ? _c(
                  "div",
                  {
                    staticClass: "btn-group",
                    attrs: { role: "group", "aria-label": "Claims Filter" }
                  },
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          { active: _vm.claimsFilter === null }
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.setClaimsFilter(null)
                          }
                        }
                      },
                      [_vm._v("All")]
                    ),
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          {
                            active:
                              _vm.claimsFilter === _vm.ClaimFilterType.unclaimed
                          }
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.setClaimsFilter(
                              _vm.ClaimFilterType.unclaimed
                            )
                          }
                        }
                      },
                      [_vm._v("Unclaimed")]
                    ),
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          {
                            active:
                              _vm.claimsFilter === _vm.ClaimFilterType.claimed
                          }
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.setClaimsFilter(
                              _vm.ClaimFilterType.claimed
                            )
                          }
                        }
                      },
                      [_vm._v("Claimed")]
                    ),
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          {
                            active:
                              _vm.claimsFilter === _vm.ClaimFilterType.claimed
                          }
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.setClaimsFilter(
                              _vm.ClaimFilterType.doubleClaimed
                            )
                          }
                        }
                      },
                      [_vm._v("Double Claimed")]
                    )
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-center justify-content-end"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn-group",
                        attrs: {
                          role: "group",
                          "aria-label": "Mappings Filter"
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            class: [
                              "btn",
                              "btn-primary",
                              { active: _vm.claimsFilter === null }
                            ],
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.setClaimsFilter(null)
                              }
                            }
                          },
                          [_vm._v("All")]
                        ),
                        _c(
                          "button",
                          {
                            class: [
                              "btn",
                              "btn-primary",
                              {
                                active:
                                  _vm.claimsFilter ===
                                  _vm.ClaimFilterType.unmapped
                              }
                            ],
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.setClaimsFilter(
                                  _vm.ClaimFilterType.unmapped
                                )
                              }
                            }
                          },
                          [_vm._v("Unmapped")]
                        ),
                        _c(
                          "button",
                          {
                            class: [
                              "btn",
                              "btn-primary",
                              {
                                active:
                                  _vm.claimsFilter ===
                                  _vm.ClaimFilterType.mapped
                              }
                            ],
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.setClaimsFilter(
                                  _vm.ClaimFilterType.mapped
                                )
                              }
                            }
                          },
                          [_vm._v("Mapped")]
                        )
                      ]
                    ),
                    _vm.claimsFilter === _vm.ClaimFilterType.mapped
                      ? _c(
                          "vue-typeahead-bootstrap",
                          {
                            attrs: {
                              size: "sm",
                              placeholder: "All WBS Categories",
                              data: _vm.allWbsCategories,
                              serializer: function(item) {
                                return _vm.capitalize(item.categoryName)
                              },
                              showOnFocus: true
                            },
                            on: {
                              hit: function($event) {
                                return _vm.updateMappedFilter($event)
                              }
                            },
                            model: {
                              value: _vm.mappedFilterText,
                              callback: function($$v) {
                                _vm.mappedFilterText = $$v
                              },
                              expression: "mappedFilterText"
                            }
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _vm.wbsCatFilterId !== 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-secondary",
                                      on: {
                                        click: function($event) {
                                          return _vm.updateMappedFilter(null)
                                        }
                                      }
                                    },
                                    [_vm._v(" x ")]
                                  )
                                : _vm._e()
                            ])
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: {
              height: "75vh",
              "overflow-y": "scroll",
              "overflow-x": "hidden"
            }
          },
          [
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane fade show active",
                  attrs: {
                    id: "claims",
                    role: "tabpanel",
                    "aria-labelledby": "claims-tab"
                  }
                },
                [
                  _c("table", { staticClass: "table table-sm table-striped" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th", { staticClass: "sow-sort" }, [
                            _vm._v("Sort Order")
                          ]),
                          _c("th", { staticClass: "sow-number" }, [
                            _vm._v("SOW Number")
                          ]),
                          _c("th", { staticClass: "sow-desc" }, [
                            _vm._v("SOW Description")
                          ]),
                          _c("th", { staticClass: "sow-type" }, [
                            _vm._v("SOW Type")
                          ]),
                          _vm._l(_vm.availableWbsCategories, function(wbsCat) {
                            return _c(
                              "th",
                              {
                                key: wbsCat.id,
                                staticClass: "wbs-cat normalized-value"
                              },
                              [_vm._v(_vm._s(wbsCat.categoryName))]
                            )
                          }),
                          _c("th", { staticClass: "spinner-width" }, [
                            _vm._v(" ")
                          ])
                        ],
                        2
                      )
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.sows.data, function(sow) {
                        return _c(
                          "tr",
                          { key: sow.id },
                          [
                            _c("td", { staticClass: "sow-sort" }, [
                              _vm._v(_vm._s(sow.sortOrder))
                            ]),
                            _c("td", { staticClass: "sow-number" }, [
                              _vm._v(_vm._s(sow.sowNumber))
                            ]),
                            _c(
                              "td",
                              { staticClass: "sow-desc" },
                              [
                                _c("limited-text-display", {
                                  attrs: {
                                    text: sow.sowDescription,
                                    cutoffLength: 50
                                  }
                                })
                              ],
                              1
                            ),
                            _c("td", { staticClass: "sow-type" }, [
                              _vm._v(
                                _vm._s(
                                  sow.statementOfWorkType
                                    ? sow.statementOfWorkType.acronym
                                    : ""
                                )
                              )
                            ]),
                            sow.statementOfWorkType &&
                            sow.statementOfWorkType.claimable
                              ? _vm._l(_vm.availableWbsCategories, function(
                                  wbsCat
                                ) {
                                  return _c(
                                    "td",
                                    { key: wbsCat.id, staticClass: "wbs-cat" },
                                    [
                                      _c("input", {
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            sow.id +
                                            "-" +
                                            wbsCat.id +
                                            "-wbscat-mapping"
                                        },
                                        domProps: {
                                          checked: _vm.doesSowHaveWbsCatClaim(
                                            sow,
                                            wbsCat
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onClaimChange(
                                              $event,
                                              sow,
                                              wbsCat
                                            )
                                          }
                                        }
                                      }),
                                      _c("label", {
                                        staticClass: "form-check-label ms-1",
                                        attrs: {
                                          for:
                                            sow.id +
                                            "-" +
                                            wbsCat.id +
                                            "-wbscat-mapping"
                                        }
                                      })
                                    ]
                                  )
                                })
                              : _c(
                                  "td",
                                  {
                                    staticClass: "wbs-cat",
                                    attrs: {
                                      colspan: _vm.availableWbsCategories.length
                                    }
                                  },
                                  [_vm._v(" ")]
                                ),
                            _c("td", { staticClass: "spinner-width" }, [
                              _vm.saving[sow.id]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border spinner-border-sm",
                                      attrs: { role: "status" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "visually-hidden" },
                                        [_vm._v("Saving...")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "tab-pane fade",
                  attrs: {
                    id: "mappings",
                    role: "tabpanel",
                    "aria-labelledby": "mappings-tab"
                  }
                },
                [
                  _c(
                    "table",
                    {
                      staticClass: "table table-sm table-striped mappings-table"
                    },
                    [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c(
                              "th",
                              {
                                staticClass: "sow-sort",
                                attrs: { scope: "col" }
                              },
                              [_vm._v("Sort Order")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sow-number",
                                attrs: { scope: "col" }
                              },
                              [_vm._v("SOW Number")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sow-desc",
                                attrs: { scope: "col" }
                              },
                              [_vm._v("SOW Description")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "sow-type",
                                attrs: { scope: "col" }
                              },
                              [_vm._v("SOW Type")]
                            ),
                            _vm._l(_vm.availableWbsCategories, function(
                              wbsCat
                            ) {
                              return _c(
                                "th",
                                {
                                  key: wbsCat.id,
                                  staticClass: "wbs-cat normalized-value",
                                  attrs: { scope: "col" }
                                },
                                [_vm._v(_vm._s(wbsCat.categoryName))]
                              )
                            }),
                            _c(
                              "th",
                              {
                                staticClass: "spinner-width",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" ")]
                            )
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.sowMappings, function(mapping) {
                          return _c(
                            "tr",
                            { key: mapping.sow.id },
                            [
                              _c("td", { staticClass: "sow-sort" }, [
                                _vm._v(_vm._s(mapping.sow.sortOrder))
                              ]),
                              _c("td", { staticClass: "sow-number" }, [
                                _vm._v(_vm._s(mapping.sow.sowNumber))
                              ]),
                              _c(
                                "td",
                                { staticClass: "sow-desc" },
                                [
                                  _c("limited-text-display", {
                                    attrs: {
                                      text: mapping.sow.sowDescription,
                                      cutoffLength: 35
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("td", { staticClass: "sow-type" }, [
                                _vm._v(
                                  _vm._s(
                                    mapping.sow.statementOfWorkType
                                      ? mapping.sow.statementOfWorkType.acronym
                                      : ""
                                  )
                                )
                              ]),
                              mapping.sow.statementOfWorkType &&
                              mapping.sow.statementOfWorkType.claimable
                                ? _vm._l(mapping.wbsCategories, function(
                                    wbsCat
                                  ) {
                                    return _c(
                                      "td",
                                      {
                                        key:
                                          wbsCat.projectWorkBreakdownStructureCategoryId,
                                        staticClass: "wbs-cat"
                                      },
                                      [
                                        wbsCat.claimed
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "vue-typeahead-bootstrap",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      placeholder: "Mapped WBS",
                                                      data: _vm.wbss,
                                                      serializer: function(
                                                        wbs
                                                      ) {
                                                        return (
                                                          wbs.wbsNumber +
                                                          " - " +
                                                          wbs.description
                                                        )
                                                      },
                                                      showOnFocus: true
                                                    },
                                                    on: {
                                                      hit: function($event) {
                                                        return _vm.onMapWbs(
                                                          $event,
                                                          wbsCat
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        wbsCat.projectWorkBreakdownStructureText,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          wbsCat,
                                                          "projectWorkBreakdownStructureText",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "wbsCat.projectWorkBreakdownStructureText"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [
                                                        wbsCat.projectWorkBreakdownStructureId
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-secondary",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onMapWbs(
                                                                      null,
                                                                      wbsCat
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v(" x ")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  })
                                : _c(
                                    "td",
                                    {
                                      staticClass: "wbs-cat",
                                      attrs: {
                                        colspan:
                                          _vm.availableWbsCategories.length
                                      }
                                    },
                                    [_vm._v(" ")]
                                  ),
                              _c("td", { staticClass: "spinner-width" }, [
                                false
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "spinner-border spinner-border-sm",
                                        attrs: { role: "status" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "visually-hidden" },
                                          [_vm._v("Saving...")]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        )
      ]),
      _c("page-nav", {
        attrs: {
          loading: _vm.loading,
          pageMeta: _vm.pageMeta,
          pageSize: _vm.pageSize
        },
        on: { pageChange: _vm.onPageChange }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item", attrs: { id: "claims-tab" } }, [
      _c(
        "a",
        {
          staticClass: "nav-link active",
          attrs: {
            href: "#",
            "aria-current": "page",
            "data-bs-toggle": "tab",
            "data-bs-target": "#claims"
          }
        },
        [_vm._v("Claims")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "nav-item", attrs: { id: "mappings-tab" } },
      [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "#",
              "data-bs-toggle": "tab",
              "data-bs-target": "#mappings"
            }
          },
          [_vm._v("Mappings")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }