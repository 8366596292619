


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CostCenterTypeContract, ResourceContract } from '@/types/dtos'
import adminDataService from '@/services/admin-data-service'
import { PaginationResult } from '@/types'
import costCenterService from '@/services/cost-center-service'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'

@Component({
  components: {}
})
export default class ResourceManagement extends Vue {

  @Prop({ default: false })
  isProjectLevel!: boolean

  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)

  loading = false
  pageSize = 100
  page = 1
  resources: ResourceContract[] = []
  costCenterTypes: PaginationResult<CostCenterTypeContract> = adminDataService.getEmptyPaginationResult<CostCenterTypeContract>()
  associations: boolean[][] = []

  mounted(): void {
    this.refreshDependencies()
    this.refreshData()
  }

  @Watch('adminState.activeProjectId')
  public async onProjectChange(): Promise<void> {
    if (this.isProjectLevel) {
      await this.refreshDependencies()
      await this.refreshData()
    }
  }

  public async refreshDependencies(): Promise<void> {
    try {
      this.loading = true
      const modelName = this.isProjectLevel ? 'project-resources' : 'resources'
      if (this.isProjectLevel) {
        if (this.adminState.activeProjectId) {
          this.resources = await adminDataService.getData(modelName, 'resourceName', undefined, this.adminState.activeProjectId)
        }
      } else {
        this.resources = await adminDataService.getData(modelName, 'resourceName')
      }
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error loading records: ${err.message}`)
    } finally {
      this.loading = false
    }
  }

  public async refreshData(pageNum?: number): Promise<void> {
    try {
      this.loading = true
      if (pageNum && pageNum !== this.page) {
        this.page = pageNum
        const path = this.isProjectLevel ? `/project-admin/resource-management` : `/company-admin/resource-management`
        this.$router.push({ path: path, query: { page: this.page.toString() }})
      }
      if (this.isProjectLevel) {
        if (this.adminState.activeProjectId) {
          this.costCenterTypes = await adminDataService.getDataPage('project-cost-center-types', 'costCenterType', this.page, this.pageSize, undefined, ['resources'], this.adminState.activeProjectId)
        }
      } else {
        this.costCenterTypes = await adminDataService.getDataPage('cost-center-types', 'costCenterType', this.page, this.pageSize, undefined, ['resources'])
      }
      this.updateAssociations()
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error loading records: ${err.message}`)
    } finally {
      this.loading = false
    }
  }

  public async updateAssociation(cct: CostCenterTypeContract, res: ResourceContract): Promise<void> {
    const adding = !cct.resources?.find(r => r.id === res.id)
    try {
      const projectId = this.isProjectLevel ? this.adminState.activeProjectId : undefined
      await costCenterService.attachDetachResource(cct.id, res.id, adding, projectId)
      await this.refreshData()
      this.toastState.showSuccess({ content: 'Resources updated', delay: 2000 })
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error saving: ${err.message}`)
    }
  }

  private updateAssociations(): void {
    this.associations = []
    for (const cct of this.costCenterTypes.data) {
      const arr: boolean[] = []
      for (const res of this.resources) {
        arr.push(!!cct.resources?.find(r => r.id === res.id))
      }
      this.associations.push(arr)
      console.log('cct', cct.costCenterType, arr)
    }
  }

  public isAssociated(cct: CostCenterTypeContract, resource: ResourceContract): boolean {
    if (!cct.resources) {
      return false
    }
    const assoc = !!cct.resources.find(r => r.id === resource.id)
    console.log()
    return assoc
  }
}
