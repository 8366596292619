var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sow-management-container" },
    [
      _c("editable-list", {
        attrs: {
          dataModelUri: "statement-of-works",
          dataModelProjectBased: true,
          listTitle:
            "SOWs for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          sortProperty: "sortOrder",
          includeRelated: ["statementOfWorkType"],
          pageSize: 100
        },
        on: { onAddNew: _vm.onAddNew },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col-1 fw-bold" }, [
                  _vm._v("Sort Order")
                ]),
                _c("div", { staticClass: "col-2 fw-bold" }, [
                  _vm._v("SOW Number")
                ]),
                _c("div", { staticClass: "col-7 fw-bold" }, [
                  _vm._v("Description")
                ]),
                _c("div", { staticClass: "col-2 fw-bold" }, [
                  _vm._v("SOW Type")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c("div", { staticClass: "col-1" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.sortOrder))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: slotProps.data.sortOrder,
                                  expression: "slotProps.data.sortOrder",
                                  modifiers: { number: true }
                                }
                              ],
                              staticClass: "form-control form-control-sm me-1",
                              attrs: {
                                type: "number",
                                step: "0.1",
                                required: ""
                              },
                              domProps: { value: slotProps.data.sortOrder },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "sortOrder",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("This field is required.")
                        ])
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.sowNumber))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.sowNumber,
                                  expression: "slotProps.data.sowNumber"
                                }
                              ],
                              staticClass: "form-control form-control-sm me-1",
                              attrs: { type: "text", required: "" },
                              domProps: { value: slotProps.data.sowNumber },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "sowNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("This field is required.")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-7" },
                        [
                          !slotProps.isEditing
                            ? _c("limited-text-display", {
                                attrs: {
                                  cutoffLength: 50,
                                  text: slotProps.data.sowDescription
                                }
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.sowDescription,
                                    expression: "slotProps.data.sowDescription"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: { type: "text", required: "" },
                                domProps: {
                                  value: slotProps.data.sowDescription
                                },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "sowDescription",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("This field is required.")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          slotProps.data.statementOfWorkType ||
                          slotProps.isEditing
                            ? [
                                !slotProps.isEditing
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "prop-display normalized-value"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            slotProps.data.statementOfWorkType
                                              .sowTypeName
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              slotProps.data
                                                .statementOfWorkTypeId,
                                            expression:
                                              "slotProps.data.statementOfWorkTypeId"
                                          }
                                        ],
                                        staticClass:
                                          "form-select form-select-sm",
                                        attrs: {
                                          "aria-label": "SOW Types",
                                          required: ""
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                slotProps.data,
                                                "statementOfWorkTypeId",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function($event) {
                                              return slotProps.setDirty()
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("Select a Type")
                                        ]),
                                        _vm._l(_vm.sowTypes, function(opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: { value: opt.id }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(opt.sowTypeName) +
                                                  " (" +
                                                  _vm._s(opt.acronym) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("This field is required.")
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }