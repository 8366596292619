var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "container-fluid project-estimation-container",
      staticStyle: { "overflow-x": "hidden" }
    },
    [
      _c("h4", { staticStyle: { height: "30px" } }, [
        _vm._v(
          "Estimation for Project " + _vm._s(_vm.adminState.activeProjectName)
        )
      ]),
      _c("div", { staticClass: "row align-items-stretch" }, [
        _c(
          "div",
          { staticClass: "col-3" },
          [_c("WorkBreakdownStructureList", { staticClass: "w-100" })],
          1
        ),
        _vm.projectEstimationState.workBreakdownStructureEstimationData
          ? _c(
              "div",
              { staticClass: "col-9" },
              [_c("WorkBreakdownStructureEstimation")],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }