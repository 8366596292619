








































import { Component } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import { ResourceType } from '@/types'
import ProjectLevelAdminEditor from '../projects/ProjectLevelAdminEditor'

@Component({
  components: {
    EditableList,
  }
})
export default class Resources extends ProjectLevelAdminEditor {
  get baseDataModelUri(): string {
    return 'resources'
  }

  resourceTypes = [{
    name: 'Select a type',
    value: undefined
  }, ...Object.keys(ResourceType).map(key => ({
    name: key,
    value: (ResourceType as any)[key] as string
  }))]

  getResourceTypeName(value: string): string {
    return Object.keys(ResourceType)
      .filter(key => (ResourceType as any)[key] === value)[0]
  }
}
