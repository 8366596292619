




































import { PaginationMetaData } from '@/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class PageNav extends Vue {
  page = 1

  @Prop({ required: true})
  pageMeta!: PaginationMetaData

  @Prop({ default: false })
  loading!: boolean

  @Prop({ default: 10 })
  pageSize!: number

  @Prop({ default: 1 })
  initialPage!: number

  get prevPages(): number[] {
    const start = Math.max(1, this.page - 5)
    const end = Math.max(1, this.page - 1)
    const diff = end - start
    console.log('start', start, 'end', end, 'diff', diff)
    return this.page === start ? [] : Array(diff + 1).fill(0).map((_, index) => start + index)
  }

  get nextPages(): number[] {
    console.log('page nav meta', this.pageMeta)
    const start = Math.min(this.page + 1, this.pageMeta.totalPages)
    const end = Math.min(this.page + 5, this.pageMeta.totalPages)
    console.log('start', start, 'end', end, 'diff', end - start)
    return this.page === end ? [] : Array(end - start + 1).fill(0).map((_, index) => start + index)
  }

  clampPageNumber(isMounting = false): void {
    if (this.page < 1) {
      this.page = 1
    } else if (!isMounting && this.page > this.pageMeta.totalPages) {
      this.page = this.pageMeta.totalPages || 1
    }
  }

  onPageChange(): void {
    this.clampPageNumber()
    this.$emit('pageChange', this.page)
  }

  setPageNumber(pageNum: number): void {
    this.page = pageNum
    this.onPageChange()
  }

  @Watch('initialPage', { immediate: true })
  onInitialPageUpdate(): void {
    this.page = this.initialPage
  }

  @Watch('pageMeta')
  onPageMetaChange(): void {
    this.page = this.pageMeta.currentPage
  }
}
