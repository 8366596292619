var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "import-export px-2 pt-1" },
    [
      _c("h2", [_vm._v("Imports for " + _vm._s(_vm.headingSuffix))]),
      this.rows.length
        ? _c(
            "div",
            _vm._l(_vm.groups, function(group, index) {
              return _c(
                "fieldset",
                { key: "group-" + index },
                [
                  _c("legend", [_vm._v("Import Set " + _vm._s(index + 1))]),
                  _vm._l(group, function(row) {
                    return _c("import-export-row", {
                      key: row.id,
                      attrs: { data: row, toast: _vm.$refs.toastDisplay }
                    })
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._l(_vm.importSets, function(importSet) {
        return _c(
          "fieldset",
          { key: importSet.name },
          [
            _c("legend", [_vm._v(_vm._s(importSet.name))]),
            _vm._l(importSet.endpointMap, function(endpoints, index) {
              return _c(
                "div",
                { key: importSet.name + "-" + index },
                [
                  _c("import-export-row", {
                    attrs: {
                      data: endpoints.primary,
                      toast: _vm.$refs.toastDisplay,
                      isProjectLevel: _vm.isProjectLevel
                    },
                    on: { fileUploaded: _vm.onFileUploaded }
                  }),
                  _vm._l(endpoints.support, function(row) {
                    return _c("import-export-row", {
                      key: row.id,
                      attrs: {
                        data: row,
                        toast: _vm.$refs.toastDisplay,
                        isSupport: true,
                        isProjectLevel: _vm.isProjectLevel
                      },
                      on: { fileUploaded: _vm.onFileUploaded }
                    })
                  })
                ],
                2
              )
            })
          ],
          2
        )
      }),
      _c("file-upload-result-modal", {
        ref: "fileUploadResultModal",
        attrs: { historyId: _vm.lastHistoryId }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }