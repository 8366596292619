var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "option-periods-container" },
    [
      _c("editable-list", {
        attrs: {
          listTitle:
            "Option Periods for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          sortProperty: ["startDate", "endDate", "optionPeriod"],
          dataModelUri: "option-periods",
          dataModelProjectBased: true
        },
        on: { onAddNew: _vm.onAddNew },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("OP Name")]),
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("Start Date")
                ]),
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("End Date")])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "input-group has-validation" },
                          [
                            !slotProps.isEditing
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "prop-display normalized-value"
                                  },
                                  [_vm._v(_vm._s(slotProps.data.optionPeriod))]
                                )
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: slotProps.data.optionPeriod,
                                      expression: "slotProps.data.optionPeriod"
                                    }
                                  ],
                                  class: [
                                    "form-control",
                                    "form-control-sm",
                                    "me-1",
                                    {
                                      "is-invalid": slotProps.getValidationError(
                                        "optionPeriod"
                                      )
                                    }
                                  ],
                                  attrs: { type: "text", required: "" },
                                  domProps: {
                                    value: slotProps.data.optionPeriod
                                  },
                                  on: {
                                    change: function($event) {
                                      return slotProps.setDirty()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        slotProps.data,
                                        "optionPeriod",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                            slotProps.getValidationError("optionPeriod")
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    _vm._s(
                                      slotProps.getValidationError(
                                        "optionPeriod"
                                      ).message
                                    )
                                  )
                                ])
                              : _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("This field is required.")
                                ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "input-group has-validation" },
                          [
                            !slotProps.isEditing
                              ? _c("span", { staticClass: "prop-display" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        slotProps.data.startDate,
                                        "MMMM dd, yyyy"
                                      )
                                    )
                                  )
                                ])
                              : _c("air-date-picker", {
                                  attrs: {
                                    id: "start-date-" + slotProps.rowIndex,
                                    dataAsISO8601: true,
                                    dateFormat: "MMMM dd, yyyy",
                                    maxDate: slotProps.data.endDate
                                  },
                                  on: {
                                    change: function($event) {
                                      return slotProps.setDirty()
                                    }
                                  },
                                  model: {
                                    value: slotProps.data.startDate,
                                    callback: function($$v) {
                                      _vm.$set(slotProps.data, "startDate", $$v)
                                    },
                                    expression: "slotProps.data.startDate"
                                  }
                                }),
                            _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "input-group has-validation" },
                          [
                            !slotProps.isEditing
                              ? _c("span", { staticClass: "prop-display" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        slotProps.data.endDate,
                                        "MMMM dd, yyyy"
                                      )
                                    )
                                  )
                                ])
                              : _c("air-date-picker", {
                                  attrs: {
                                    id: "start-date-" + slotProps.rowIndex,
                                    dataAsISO8601: true,
                                    dateFormat: "MMMM dd, yyyy",
                                    minDate: slotProps.data.startDate
                                  },
                                  on: {
                                    change: function($event) {
                                      return slotProps.setDirty()
                                    }
                                  },
                                  model: {
                                    value: slotProps.data.endDate,
                                    callback: function($$v) {
                                      _vm.$set(slotProps.data, "endDate", $$v)
                                    },
                                    expression: "slotProps.data.endDate"
                                  }
                                }),
                            _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }