




























import { Component } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import { CostCenterContract, CostCenterTypeContract, ResourceContract } from '@/types/dtos'
import ProjectLevelAdminEditor from '../projects/ProjectLevelAdminEditor'

@Component({
  components: {
    EditableList,
  }
})
export default class CostCenterTypes extends ProjectLevelAdminEditor {

  get baseDataModelUri(): string {
    return 'cost-center-types'
  }

  getConfirmDeleteMessage(data: CostCenterTypeContract): string {
    const costCenters = data.costCenters?.map((cc: CostCenterContract) => cc.name).join(', ') || ''
    const resources = data.resources?.map((r: ResourceContract) => r.resourceName).join(', ') || ''
    let message = 'Are you sure you want to delete this cost center type?'
    if (costCenters) {
      message += `  The following cost centers will also be deleted: ${costCenters}.`
    }
    if (resources) {
      message += `  The following resources will also be detached from this cost center: ${resources}.`
    }
    return message
  }
}
