import { BaseHttpService } from './base-http-service'
import { BoeBokContract } from '@/types/dtos'

class BoeBokService extends BaseHttpService {
    public async createBoeBok(boeBok: Partial<BoeBokContract>): Promise<BoeBokContract> {
        const uri = '/api/boe-boks'
        const response = await this.doApiPost<BoeBokContract>(uri, JSON.stringify({ data: boeBok }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }

    public async updateBoeBok(id: number, boeBok: Partial<BoeBokContract>): Promise<BoeBokContract> {
        const uri = `/api/boe-boks/${id}`
        const response = await this.doApiPut<BoeBokContract>(uri, JSON.stringify({ data: boeBok }))
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }
}

export default new BoeBokService()
