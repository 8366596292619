



















































































import { Component, Watch } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import LimitedTextDisplay from '@/components/util/LimitedTextDisplay.vue'
import { WbsManagementDependencies } from '@/types'
import workBreakdownStructureService from '@/services/work-breakdown-structure-service'
import ProjectLevelAdminEditor from './ProjectLevelAdminEditor'

@Component({
  components: {
    EditableList,
    LimitedTextDisplay,
  }
})
export default class WorkBreakdownStructureManagement extends ProjectLevelAdminEditor {

  get relatedModels(): string[] {
    return this.isProjectLevel ? ['workBreakdownStructureCategory', 'responsibleOwner'] : ['workBreakdownStructureCategory']
  }

  get baseDataModelUri(): string {
    return 'work-breakdown-structures'
  }

  editorDeps: WbsManagementDependencies = workBreakdownStructureService.getEmptyEditorDependencies()

  async mounted(): Promise<void> {
    this.editorDeps = await workBreakdownStructureService.getEditorDependencies(this.isProjectLevel ? this.adminState.activeProjectId : undefined)
  }

  @Watch('adminState.activeProjectId')
  public async onProjectChange(): Promise<void> {
    this.editorDeps = await workBreakdownStructureService.getEditorDependencies(this.isProjectLevel ? this.adminState.activeProjectId : undefined)
  }

  private safeGet(data: any, prop: string, defaultVal = ''): string {
    if (data) {
      return data[prop]
    }
    return defaultVal
  }
}
