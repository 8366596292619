import AdminState from '@/store/modules/admin'
import { Prop, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

export default abstract class ProjectLevelAdminEditor extends Vue {
  adminState: AdminState = getModule(AdminState)

  @Prop({ default: false })
  public isProjectLevel!: boolean

  abstract get baseDataModelUri(): string

  get dataModelUri(): string {
    return this.isProjectLevel ? `project-${this.baseDataModelUri}` : this.baseDataModelUri
  }

  get dataModelSearchParams(): Record<string, unknown> {
    if (this.isProjectLevel) {
      return { project_id: this.adminState.activeProjectId }
    }
    return {}
  }
}