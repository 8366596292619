import { PaginationResult, SowClaimsMappingDepenendencies } from '@/types'
import { StatementOfWorkContract } from '@/types/dtos'
import { BaseHttpService } from './base-http-service'

export enum ClaimFilterType {
  claimed = 'claimed',
  unclaimed = 'unclaimed',
  doubleClaimed = 'doubleClaimed',
  mapped = 'mapped',
  unmapped = 'unmapped'
}

class StatementOfWorksService extends BaseHttpService {
  public async getStatementOfWorks(projectId: number, pageSize: number, pageNumber = 1, claimsFilter?: ClaimFilterType, onlyWbsCatId?: number): Promise<PaginationResult<StatementOfWorkContract>> {
    const params = new URLSearchParams()
    params.set('page', pageNumber.toString())
    params.set('pageSize', pageSize.toString())
    params.set('include_sow_type', 'true')
    params.set('include_wbs_cat', 'true')
    params.set('include_wbs', 'true')
    if (claimsFilter) {
      params.set(claimsFilter.toString(), 'true')
    }
    if (onlyWbsCatId && onlyWbsCatId > 0) {
      params.set('wbs_cat_id', onlyWbsCatId.toString())
    }
    const uri = `/api/projects/${projectId}/statement-of-works?${params.toString()}`
    const response = await this.doApiGet<PaginationResult<StatementOfWorkContract>>(uri)
    if (response.success) {
      if (response.response) {
        response.response.meta.totalPages = Math.ceil(response.response.meta.total / response.response.meta.perPage)
        return response.response
      }
      return this.getEmptyPaginationResult(pageSize)
    } else {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }

  public async getEditorDependencies(projectId: number): Promise<SowClaimsMappingDepenendencies> {
    const uri = `/api/projects/${projectId}/editor-data/sow-claims-mapping`
    const response = await this.doApiGet<SowClaimsMappingDepenendencies>(uri)
    if (response.success) {
      return response.response!
    } else {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }

  public async claimStatementOfWork(projectId: number, statementOfWorkId: number, projectWorkBreakdownStructureCategoryId: number): Promise<void> {
    const uri = `/api/projects/${projectId}/statement-of-works/${statementOfWorkId}/claim`
    const response = await this.doApiPost(uri, JSON.stringify({ projectWorkBreakdownStructureCategoryId }))
    if (!response.success) {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }

  public async unclaimStatementOfWork(projectId: number, statementOfWorkId: number, projectWorkBreakdownStructureCategoryId: number): Promise<void> {
    const uri = `/api/projects/${projectId}/statement-of-works/${statementOfWorkId}/unclaim`
    const response = await this.doApiPost(uri, JSON.stringify({ projectWorkBreakdownStructureCategoryId }))
    if (!response.success) {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }

  public async mapSowToWbs(projectId: number, statementOfWorkId: number, projectWorkBreakdownStructureCategoryId: number, projectWorkBreakdownStructureId: number): Promise<void> {
    const uri = `/api/projects/${projectId}/statement-of-works/${statementOfWorkId}/map-wbs`
    const response = await this.doApiPost(uri, JSON.stringify({ projectWorkBreakdownStructureCategoryId, projectWorkBreakdownStructureId }))
    if (!response.success) {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }

  public async unmapSowFromWbs(projectId: number, statementOfWorkId: number, projectWorkBreakdownStructureCategoryId: number, projectWorkBreakdownStructureId: number | null): Promise<void> {
    const uri = `/api/projects/${projectId}/statement-of-works/${statementOfWorkId}/unmap-wbs`
    const response = await this.doApiPost(uri, JSON.stringify({ projectWorkBreakdownStructureCategoryId, projectWorkBreakdownStructureId }))
    if (!response.success) {
      throw this.handleError(response.error?.message || 'Request failed but no error sent')
    }
  }
}

export default new StatementOfWorksService()
