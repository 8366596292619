var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { tabindex: "-1", id: "file-upload-result-modal" }
    },
    [
      _c(
        "div",
        { class: ["modal-dialog", "modal-lg", "modal-dialog-scrollable"] },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body" }, [
              _c("ul", { staticClass: "list-group" }, [
                _c(
                  "li",
                  { staticClass: "list-group-item list-group-item-secondary" },
                  [
                    _vm._v(
                      "Uploaded " + _vm._s(_vm.uploadResult.size) + " bytes"
                    )
                  ]
                ),
                _c(
                  "li",
                  {
                    class: [
                      "list-group-item",
                      {
                        "list-group-item-success":
                          _vm.currentStatus === "complete" && !_vm.errors.length
                      }
                    ]
                  },
                  [
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v(_vm._s(_vm.currentStatus))
                    ]),
                    _vm._v(": " + _vm._s(_vm.progress) + " "),
                    _vm.currentStatus !== "complete"
                      ? _c(
                          "div",
                          {
                            staticClass: "spinner-border spinner-border-sm",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Processing...")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "accordion",
                  attrs: { id: "messagesAccordion" }
                },
                [
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "accordion-header",
                        attrs: { id: "headingMessages" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-button btn-info",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "collapse",
                              "data-bs-target": "#collapseMessages",
                              "aria-expanded": "false",
                              "aria-controls": "collapseMessages"
                            }
                          },
                          [
                            _vm._v(
                              " Messages (" + _vm._s(_vm.messages.length) + ") "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "accordion-collapse collapse",
                        attrs: {
                          id: "collapseMessages",
                          "aria-labelledby": "headingMessages",
                          "data-bs-parent": "#messagesAccordion"
                        }
                      },
                      [
                        _c("ul", { staticClass: "accordion-body" }, [
                          _vm.messages && _vm.messages.length
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "list-group-item list-group-item-info"
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.messages, function(msg, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(_vm._s(msg))
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "accordion-header",
                        attrs: { id: "headingErrors" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-button btn-danger",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "collapse",
                              "data-bs-target": "#collapseErrors",
                              "aria-expanded": "false",
                              "aria-controls": "collapseErrors"
                            }
                          },
                          [
                            _vm._v(
                              " Errors (" + _vm._s(_vm.errors.length) + ") "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "accordion-collapse collapse",
                        attrs: {
                          id: "collapseErrors",
                          "aria-labelledby": "headingMessages",
                          "data-bs-parent": "#messagesAccordion"
                        }
                      },
                      [
                        _c("ul", { staticClass: "accordion-body list-group" }, [
                          _vm.errors && _vm.errors.length
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "list-group-item list-group-item-danger"
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function(error, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(_vm._s(error))
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                ]
              )
            ]),
            _vm._m(1)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("File Upload Results")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-secondary",
          attrs: { type: "button", "data-bs-dismiss": "modal", title: "Close" }
        },
        [_c("i", { staticClass: "bi-x-lg me-1" }), _vm._v("Close ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }