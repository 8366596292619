
























import { Vue } from 'vue-property-decorator'
import AdminState from '@/store/modules/admin'
import ProjectEstimationState from '@/store/modules/project-estimation-state'
import { getModule } from 'vuex-module-decorators'

export default class WorkBreakdownStructureList extends Vue {
  adminState: AdminState = getModule(AdminState)
  projectEstimationState: ProjectEstimationState = getModule(ProjectEstimationState)
  filterType: 'all'|'noOwner' = 'all'
  searchText = ''

  filterTypeChange(filterType: 'all'|'noOwner') {
    this.filterType = filterType
    this.filterList()
  }

  searchTextChange(searchText: string) {
    this.searchText = searchText
    this.filterList()
  }

  filterList() {
    this.projectEstimationState.setWorkBreakdownStructuresFilteredList({ filterType: this.filterType, searchText: this.searchText })
  }

  selectWorkBreakdownStructure(id: number) {
    this.projectEstimationState.setWorkBreakdownStructureId(id)
  }
}
