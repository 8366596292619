




















































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import AdminState from '@/store/modules/admin'
import ProjectEstimationState, { EstimationCostCenterData } from '@/store/modules/project-estimation-state'
import { getModule } from 'vuex-module-decorators'
import { BoeBokContract, BoeBokEstimateContract, BoeBokEstimateMonthContract, NewBoeBok, ProjectResourceContract } from '@/types/dtos'
import AirDatePicker from '@/components/util/AirDatePicker.vue'
import Common from '@/util/common'
import { ResourceType } from '@/types'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ToastState from '@/store/modules/toast'

@Component({
  components: {
    AirDatePicker,
    ConfirmModal,
  }
})
export default class WorkBreakdownStructureEstimation extends Vue {
  adminState: AdminState = getModule(AdminState)
  projectEstimationState: ProjectEstimationState = getModule(ProjectEstimationState)
  editingBoeBokEstimate: BoeBokEstimateContract|null = null
  wbsDataChanged = false
  selectedResourceId = -1
  estimatingRationale = ''
  editingIndex = -1
  monthsDataChanged = false
  @Ref('deleteModal')
  confirmDeleteModal!: ConfirmModal
  deleteConfirmationMessageText = 'Are you sure you want to delete this record?'
  toastState: ToastState = getModule(ToastState)

  getCostCenterResources(estimationCostCenterData: EstimationCostCenterData, currentProjectResourceId: number|null = null) {
    const resources: ProjectResourceContract[] = []

    if (estimationCostCenterData.costCenter?.projectCostCenterType?.resources?.length) {
      for (const resource of estimationCostCenterData.costCenter.projectCostCenterType.resources) {
        if (!estimationCostCenterData.boeBok?.boeBokEstimates?.length || !estimationCostCenterData.boeBok.boeBokEstimates.find(e => e.projectResourceId === resource.id) || (currentProjectResourceId && resource.id === currentProjectResourceId)) {
          resources.push(resource)
        }
      }
    }

    return resources
  }

  validateMonths(boeBokEstimateMonth: BoeBokEstimateMonthContract, resourceType: ResourceType): void {
    if (boeBokEstimateMonth.estimateAmount.toString() === '' || boeBokEstimateMonth.estimateAmount < 0) {
      boeBokEstimateMonth.estimateAmount = 0
    } else {
      boeBokEstimateMonth.estimateAmount = resourceType === ResourceType.Money ?
          Math.round((boeBokEstimateMonth.estimateAmount + Number.EPSILON) * 100) / 100 :
          Math.round((boeBokEstimateMonth.estimateAmount + Number.EPSILON) * 10) / 10
    }
  }

  editMonths(index: number, boeBokEstimate: BoeBokEstimateContract): void {
    this.editingIndex = index
    this.editingBoeBokEstimate = JSON.parse(JSON.stringify(boeBokEstimate))
  }

  cancelAll(): void {
    this.cancelAddResource()
    this.cancelEditMonths()
  }

  cancelEditMonths(): void {
    this.editingIndex = -1
    this.editingBoeBokEstimate = null
    this.monthsDataChanged = false
  }

  cancelAddResource(): void {
    this.selectedResourceId = -1
    this.estimatingRationale = ''
  }

  async saveNewBoeBokEstimate(boeBok: NewBoeBok): Promise<void> {
    if (!this.adminState.loggedInUser) {
      await this.adminState.getLoggedInUser(this.$auth.user.email)
    }

    if (this.selectedResourceId !== -1 && this.estimatingRationale) {
      const newBoeBokEstimate: Partial<BoeBokEstimateContract> = {
        boeBokId: boeBok.id,
        userId: this.adminState.loggedInUser!.id,
        projectResourceId: this.selectedResourceId,
        estimatingRationale: this.estimatingRationale,
      }

      const boeBokEstimateId = await this.projectEstimationState.saveBoeBokEstimate({ boeBokEstimate: newBoeBokEstimate })

      if (boeBokEstimateId !== -1) {
        const boeBokEstimateMonths = this.createBoeBokEstimateMonths(boeBokEstimateId, boeBok.periodOfPerformanceStart!, boeBok.periodOfPerformanceEnd!)

        await this.projectEstimationState.saveBoeBokEstimateMonths({ boeBokEstimateId: boeBokEstimateId, boeBokEstimateMonths: boeBokEstimateMonths, saveType: 'create' })
      }

      this.cancelAddResource()

      await this.projectEstimationState.loadWorkBreakdownEstimationStructureData()
      this.toastState.showSuccess('Save successful.')
    }
  }

  async deleteBoeBokEstimate(id: number): Promise<void> {
    const destroy = await this.confirmDeleteModal.show()
    if (destroy) {
      await this.projectEstimationState.deleteBoeBokEstimate(id)

      await this.projectEstimationState.loadWorkBreakdownEstimationStructureData()
    }
  }

  async updateBoeBokEstimateAndMonths(boeBokEstimate: BoeBokEstimateContract): Promise<void> {
    const updatedBoeBokEstimate: Partial<BoeBokEstimateContract> = {
      id: boeBokEstimate.id,
      projectResourceId: boeBokEstimate.projectResourceId,
      estimatingRationale: boeBokEstimate.estimatingRationale,
    }

    await this.projectEstimationState.saveBoeBokEstimate({ boeBokEstimate: updatedBoeBokEstimate })

    await this.projectEstimationState.saveBoeBokEstimateMonths({ boeBokEstimateId: boeBokEstimate.id, boeBokEstimateMonths: boeBokEstimate.boeBokEstimateMonths!, saveType: 'update' })

    await this.projectEstimationState.loadWorkBreakdownEstimationStructureData()

    this.cancelEditMonths()
    this.toastState.showSuccess('Save successful.')
  }

  getRowTotal(boeBokEstimate: BoeBokEstimateContract | null): string {
    let total = 0
    if (!boeBokEstimate) return '0'

    if (boeBokEstimate.boeBokEstimateMonths?.length) {
      for (const boeBokEstimateMonth of boeBokEstimate.boeBokEstimateMonths) {
        if (boeBokEstimateMonth.estimateAmount.toString() === '') {
          return '0'
        }
        total += boeBokEstimateMonth.estimateAmount
      }
    }

    return boeBokEstimate.projectResource!.resourceType === ResourceType.Hours ? total.toFixed(1) : `$${total.toFixed(2)}`
  }

  getEstimateMonthStrings(boeBokEstimate: BoeBokEstimateContract): string[] {
    const estimateMonthStrings: string[] = []

    if (boeBokEstimate.boeBokEstimateMonths?.length) {
      for (const boeBokEstimateMonth of boeBokEstimate.boeBokEstimateMonths) {
        estimateMonthStrings.push(`${boeBokEstimateMonth.estimateMonth + 1}/${boeBokEstimateMonth.estimateYear}`)
      }
    }

    return estimateMonthStrings
  }

  async updateWbsData(): Promise<void> {
    this.wbsDataChanged = false
    await this.projectEstimationState.updateWorkBreakdownStructureData()
    this.toastState.showSuccess('Save successful.')
  }

  revertWbsData(): void {
    this.wbsDataChanged = false
    this.projectEstimationState.setWorkBreakdownStructureEstimationData(this.projectEstimationState.originalWorkBreakdownStructureEstimationData)
  }

  async saveBoeBok(optionPeriodKey: string, boeBok: BoeBokContract|NewBoeBok): Promise<void> {
    await this.projectEstimationState.saveBoeBok({ optionPeriodKey: optionPeriodKey, boeBok: boeBok })
    this.projectEstimationState.getClinPoPStart(optionPeriodKey)
    this.projectEstimationState.getClinPoPEnd(optionPeriodKey)
    this.toastState.showSuccess('Save successful.')
  }

  getResponsibleOwnerName(): string {
    const firstName = this.projectEstimationState.workBreakdownStructureEstimationData?.responsibleOwner?.firstName || ''
    const lastName = this.projectEstimationState.workBreakdownStructureEstimationData?.responsibleOwner?.lastName || ''

    return !firstName && !lastName ? 'No owner assigned' : firstName.concat(' ', lastName)
  }

  createBoeBokEstimateMonths(boeBokEstimateId: number, start: string|Date, end: string|Date): Partial<BoeBokEstimateMonthContract>[] {
    const boeBokEstimateMonths: Partial<BoeBokEstimateMonthContract>[] = []

    end = Common.dateOnly(end)
    let dateNums = end.split('-')
    const endYear = parseInt(dateNums[0])
    const endMonth = parseInt(dateNums[1]) - 1
    start = Common.dateOnly(start)
    dateNums = start.split('-')
    let currYear = parseInt(dateNums[0])
    let currMonth = parseInt(dateNums[1]) - 1
    let currMonthString = ''
    const endMonthString = `${endMonth}/${endYear}`

    while (currMonthString !== endMonthString) {
        boeBokEstimateMonths.push({
            boeBokEstimateId: boeBokEstimateId,
            estimateMonth: currMonth,
            estimateYear: currYear,
            estimateAmount: 0,
        })

        currMonthString = `${currMonth}/${currYear}`
        currMonth = currMonth + 1 === 12 ? 0 : currMonth + 1
        currYear = currMonth === 0 ? currYear + 1 : currYear
    }

    return boeBokEstimateMonths
  }

  isRowEditing(index: number) {
    return this.editingIndex === index
  }
}
