var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "clin-type-container" },
    [
      _c("editable-list", {
        attrs: {
          listTitle:
            "Contract Data Requirements for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          sortProperty: "cdrlNumber",
          dataModelUri: "contract-data-requirements",
          uri: "cdrl",
          dataModelProjectBased: true
        },
        on: { onAddNew: _vm.onAddNew },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("CDRL Number")
                ]),
                _c("div", { staticClass: "col fw-bold" }, [
                  _vm._v("Description")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c("div", { staticClass: "col" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.cdrlNumber))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.cdrlNumber,
                                  expression: "slotProps.data.cdrlNumber"
                                }
                              ],
                              class: [
                                "form-control",
                                "form-control-sm",
                                "me-1",
                                {
                                  "is-invalid": slotProps.getValidationError(
                                    "cdrlNumber"
                                  )
                                }
                              ],
                              attrs: { type: "text", required: "" },
                              domProps: { value: slotProps.data.cdrlNumber },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "cdrlNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        slotProps.getValidationError("cdrlNumber")
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                _vm._s(
                                  slotProps.getValidationError("cdrlNumber")
                                    .message
                                )
                              )
                            ])
                          : _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                      ]),
                      _c("div", { staticClass: "col" }, [
                        !slotProps.isEditing
                          ? _c("span", { staticClass: "prop-display" }, [
                              _vm._v(_vm._s(slotProps.data.cdrlDescription))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.cdrlDescription,
                                  expression: "slotProps.data.cdrlDescription"
                                }
                              ],
                              class: [
                                "form-control",
                                "form-control-sm",
                                "me-1",
                                {
                                  "is-invalid": slotProps.getValidationError(
                                    "cdrlDescription"
                                  )
                                }
                              ],
                              attrs: { type: "text", required: "" },
                              domProps: {
                                value: slotProps.data.cdrlDescription
                              },
                              on: {
                                change: function($event) {
                                  return slotProps.setDirty()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    slotProps.data,
                                    "cdrlDescription",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        slotProps.getValidationError("cdrlDescription")
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                _vm._s(
                                  slotProps.getValidationError(
                                    "cdrlDescription"
                                  ).message
                                )
                              )
                            ])
                          : _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("This field is required.")
                            ])
                      ])
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }