var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editable-list", {
    attrs: {
      dataModelUri: _vm.dataModelUri,
      uri: "resources",
      sortProperty: "resourceName",
      listTitle:
        "Resources for " +
        (_vm.isProjectLevel
          ? _vm.adminState.activeProjectName
          : _vm.adminState.activeCompanyName),
      dataModelProjectBased: _vm.isProjectLevel
    },
    scopedSlots: _vm._u([
      {
        key: "row-header-content",
        fn: function() {
          return [
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("ID")]),
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("Created At")]),
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("Updated At")]),
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("Name")]),
            _c("div", { staticClass: "col fw-bold" }, [_vm._v("Type")])
          ]
        },
        proxy: true
      },
      {
        key: "row-content",
        fn: function(slotProps) {
          return [
            !slotProps.loading
              ? [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(_vm._s(slotProps.data.id))
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          slotProps.data.createdAt,
                          "MM/dd/yyyy hh:mm aaa"
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          slotProps.data.updatedAt,
                          "MM/dd/yyyy hh:mm aaa"
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "col input-group has-validation" }, [
                    !slotProps.isEditing
                      ? _c(
                          "span",
                          { staticClass: "prop-display normalized-value" },
                          [_vm._v(_vm._s(slotProps.data.resourceName))]
                        )
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: slotProps.data.resourceName,
                              expression: "slotProps.data.resourceName"
                            }
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "text", required: "" },
                          domProps: { value: slotProps.data.resourceName },
                          on: {
                            change: function($event) {
                              return slotProps.setDirty()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                slotProps.data,
                                "resourceName",
                                $event.target.value
                              )
                            }
                          }
                        }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("This field is required.")
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col input-group input-group-sm has-validation"
                    },
                    [
                      !slotProps.isEditing
                        ? _c(
                            "span",
                            { staticClass: "prop-display normalized-value" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getResourceTypeName(
                                    slotProps.data.resourceType
                                  )
                                )
                              )
                            ]
                          )
                        : _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: slotProps.data.resourceType,
                                  expression: "slotProps.data.resourceType"
                                }
                              ],
                              staticClass: "form-select form-select-sm",
                              attrs: { required: "" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      slotProps.data,
                                      "resourceType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    return slotProps.setDirty()
                                  }
                                ]
                              }
                            },
                            _vm._l(_vm.resourceTypes, function(resourceType) {
                              return _c(
                                "option",
                                {
                                  key: resourceType.value,
                                  domProps: { value: resourceType.value }
                                },
                                [_vm._v(_vm._s(resourceType.name))]
                              )
                            }),
                            0
                          ),
                      _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("This field is required.")
                      ])
                    ]
                  )
                ]
              : [
                  _c("span", { staticClass: "placeholder col" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col mx-1" }),
                  _c("span", { staticClass: "placeholder col" })
                ]
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }