





















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import ImportExportRow from '../admin/ImportExportRow.vue'
import ToastDisplay from '../ToastDisplay.vue'
import EditableRow from '../EditableRow.vue'
import FileUploadResultModal from './FileUploadResultModal.vue'
import { FileUploadResultContract, ImportSetUIData, UploadEndpointContract } from '@/types/index'
import uploadService from '@/services/upload-service'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'

@Component({
  components: {
    EditableRow,
    FileUploadResultModal,
    ImportExportRow,
    ToastDisplay,
  }
})
export default class ImportExport extends Vue {
  private rows: UploadEndpointContract[] = []
  private groups: UploadEndpointContract[][] = []
  private importSets: ImportSetUIData[] = []
  private lastHistoryId = 0

  @Prop({ default: false })
  public isProjectLevel!: boolean

  adminState: AdminState = getModule(AdminState)

  @Ref('fileUploadResultModal')
  readonly uploadResultModal!: FileUploadResultModal

  get headingSuffix(): string {
    return this.isProjectLevel && this.adminState.activeProject ? this.adminState.activeProject!.projectName : '{Current Company}'
  }

  async mounted(): Promise<void> {
    this.importSets = await uploadService.getUploads(this.isProjectLevel) || []
  }

  public makeDirty(): void {
    (this.$refs.er as EditableRow).setDirty()
  }

  public onFileUploaded(fileUploadResult: FileUploadResultContract[]): void {
    this.uploadResultModal.show(fileUploadResult[0])
  }
}
