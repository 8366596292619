var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sow-clin-cdrl-crosswalk-container" },
    [
      _c("editable-list", {
        attrs: {
          listTitle:
            "SOW / CLIN / CDRL Crosswalk for " +
            (_vm.adminState.activeProject
              ? _vm.adminState.activeProject.projectName
              : "(No Project)"),
          dataModelUri: "statement-of-works",
          uri: "sow-clin-crosswalk",
          includeRelated: [
            "contractLineItemNumbers",
            "contractDataRequirements"
          ],
          dataModelProjectBased: true,
          sortProperty: "sortOrder",
          pageSize: 100,
          showCreateButton: false
        },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col-1 fw-bold" }, [
                  _vm._v("Sort Order")
                ]),
                _c("div", { staticClass: "col-2 fw-bold" }, [
                  _vm._v("SOW Number")
                ]),
                _c("div", { staticClass: "col-3 fw-bold" }, [
                  _vm._v("SOW Description")
                ]),
                _c("div", { staticClass: "col-3 fw-bold" }, [
                  _vm._v("Applicable CLIN(s)")
                ]),
                _c("div", { staticClass: "col-3 fw-bold" }, [
                  _vm._v("Applicable CDRL(s)")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c("div", { staticClass: "col-1" }, [
                        _c("span", { staticClass: "prop-display" }, [
                          _vm._v(_vm._s(slotProps.data.sortOrder))
                        ])
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _c("span", { staticClass: "prop-display" }, [
                          _vm._v(_vm._s(slotProps.data.sowNumber))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("limited-text-display", {
                            attrs: {
                              cutoffLength: 30,
                              text: slotProps.data.sowDescription
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          slotProps.isEditing
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between align-items-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      _vm._l(
                                        slotProps.data.contractLineItemNumbers,
                                        function(clin) {
                                          return _c(
                                            "span",
                                            {
                                              key:
                                                slotProps.data.id +
                                                "-" +
                                                clin.id,
                                              staticClass:
                                                "badge bg-primary clickable me-1",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeClin(
                                                    slotProps.data,
                                                    clin.id,
                                                    slotProps.setDirty
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    clin.contractLineItemNumber
                                                  ) +
                                                  " "
                                              ),
                                              _c("i", {
                                                staticClass: "bi-x ms-1"
                                              })
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "form-select form-select-sm w-auto d-inline",
                                        attrs: {
                                          "aria-label": "CLINs",
                                          disabled:
                                            _vm.availableClins(slotProps.data)
                                              .length === 0
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.addClin(
                                              slotProps.data,
                                              $event,
                                              slotProps.setDirty
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm.availableClins(slotProps.data)
                                            .length > 0
                                            ? _c("span", [_vm._v("Add a CLIN")])
                                            : _c("span", [
                                                _vm._v("No CLINs Available")
                                              ])
                                        ]),
                                        _vm._l(
                                          _vm.availableClins(slotProps.data),
                                          function(opt) {
                                            return _c(
                                              "option",
                                              {
                                                key: opt.id,
                                                domProps: { value: opt.id }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    opt.contractLineItemNumber
                                                  )
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            : [
                                !slotProps.data.contractLineItemNumbers ||
                                slotProps.data.contractLineItemNumbers
                                  .length === 0
                                  ? _c("span", { staticClass: "fst-italic" }, [
                                      _vm._v("(None)")
                                    ])
                                  : _vm._l(
                                      slotProps.data.contractLineItemNumbers,
                                      function(clin) {
                                        return _c(
                                          "span",
                                          {
                                            key:
                                              slotProps.data.id + "-" + clin.id,
                                            staticClass:
                                              "badge bg-primary opacity-75 me-1"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  clin.contractLineItemNumber
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      }
                                    )
                              ]
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          slotProps.isEditing
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between align-items-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      _vm._l(
                                        slotProps.data.contractDataRequirements,
                                        function(cdrl) {
                                          return _c(
                                            "span",
                                            {
                                              key:
                                                slotProps.data.id +
                                                "-" +
                                                cdrl.id,
                                              staticClass:
                                                "badge bg-primary clickable me-1",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeCdrl(
                                                    slotProps.data,
                                                    cdrl.id,
                                                    slotProps.setDirty
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(cdrl.cdrlNumber) +
                                                  " "
                                              ),
                                              _c("i", {
                                                staticClass: "bi-x ms-1"
                                              })
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "select",
                                      {
                                        staticClass:
                                          "form-select form-select-sm w-auto d-inline",
                                        attrs: {
                                          "aria-label": "CDRLs",
                                          disabled:
                                            _vm.availableCdrls(slotProps.data)
                                              .length === 0
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.addCdrl(
                                              slotProps.data,
                                              $event,
                                              slotProps.setDirty
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm.availableCdrls(slotProps.data)
                                            .length > 0
                                            ? _c("span", [_vm._v("Add a CDRL")])
                                            : _c("span", [
                                                _vm._v("No CDRLs Available")
                                              ])
                                        ]),
                                        _vm._l(
                                          _vm.availableCdrls(slotProps.data),
                                          function(opt) {
                                            return _c(
                                              "option",
                                              {
                                                key: opt.id,
                                                domProps: { value: opt.id }
                                              },
                                              [_vm._v(_vm._s(opt.cdrlNumber))]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            : [
                                !slotProps.data.contractDataRequirements ||
                                slotProps.data.contractDataRequirements
                                  .length === 0
                                  ? _c("span", { staticClass: "fst-italic" }, [
                                      _vm._v("(None)")
                                    ])
                                  : _vm._l(
                                      slotProps.data.contractDataRequirements,
                                      function(cdrl) {
                                        return _c(
                                          "span",
                                          {
                                            key:
                                              slotProps.data.id + "-" + cdrl.id,
                                            staticClass:
                                              "badge bg-primary opacity-75 me-1"
                                          },
                                          [_vm._v(_vm._s(cdrl.cdrlNumber))]
                                        )
                                      }
                                    )
                              ]
                        ],
                        2
                      )
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col mx-1" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }