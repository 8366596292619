var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "datePickerEl",
    attrs: { type: "text", placeholder: "Choose a Date" },
    on: { keydown: _vm.preventManual }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }