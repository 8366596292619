import { FileUploadResultContract, ImportSetUIData, UploadEndpointContract } from '@/types'
import { BaseHttpService } from './base-http-service'

class UploadService extends BaseHttpService {
  public getBaseUrl(): string {
    return this.baseUrl
  }

  public getWsUrl(): string {
    return this.baseWsUrl
  }

  public async getUploads(projectBased = false): Promise<ImportSetUIData[]|undefined> {
    const params = new URLSearchParams()
    params.append('sort', 'importPriority')
    params.append('sort_dir', 'asc')
    if (projectBased) {
      params.append('project_based', 'true')
    }
    const uri = `/api/boepro-uploads?${params.toString()}`
    const response = await this.doApiGet<UploadEndpointContract[]>(uri)
    if (response.success) {
      return this.transformUploadResponse(response.response)
    }
    throw new Error(response.error?.message || 'Unknown error')
  }

  public async uploadFile(endpoint: UploadEndpointContract, file: File, bodyData?: { [index: string]: any }): Promise<FileUploadResultContract> {
    const uri = `/api/uploads/${endpoint.uri}`
    const formData = new FormData()
    formData.append('file_upload', file) // the param name the file upload module expects
    if (bodyData) {
      for (const prop in bodyData) {
        formData.append(prop, bodyData[prop])
      }
    }
    const response = await this.doFileUpload<FileUploadResultContract>('POST', uri, formData)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error?.message || 'Request failed but no error sent')
  }

  private transformUploadResponse(endpoints?: UploadEndpointContract[]) {
    const importData: ImportSetUIData[] = []
    const indexMap: { [index:string]: number } = {}

    if (!endpoints) {
      return importData
    }

    let importSet: ImportSetUIData
    for (const ep of endpoints) {
      if (ep.importSet) {
        if (indexMap[ep.importSet.name] !== undefined) {
          importSet = importData[indexMap[ep.importSet.name]]
        } else {
          importSet = {
            name: ep.importSet.name,
            priority: ep.importSet.priority,
            endpointMap: [],
          }
          importData.push(importSet)
          indexMap[importSet.name] = importData.length-1
        }
        if (!ep.parentUploadEndpointId) {
          importSet.endpointMap.push({ primary: ep, support: [] })
        } else {
          const parent = importSet.endpointMap.find(entry => entry.primary.id === ep.parentUploadEndpointId)
          parent?.support.push(ep)
        }
      }
    }
    for (const impSet of importData) {
      for (const parent of impSet.endpointMap) {
        parent.support.sort((a, b) => {
          if (!a.importPriority || !b.importPriority) return 0
          return a.importPriority - b.importPriority
        })
      }
    }
    return importData.sort((a, b) => a.priority - b.priority)
  }
}

export default new UploadService()