var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "limited-text-display" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.text && _vm.text.length > _vm.cutoffLength,
                expression: "text && text.length > cutoffLength"
              }
            ],
            staticClass: "toggle-btn",
            attrs: { type: "button" },
            on: { click: _vm.toggleExpanded }
          },
          [
            _c("i", {
              class: {
                "bi-chevron-up": _vm.expanded,
                "bi-chevron-down": !_vm.expanded
              }
            })
          ]
        ),
        _c(
          "span",
          {
            class: ["prop-display", { expanded: _vm.expanded }],
            attrs: { id: _vm.id }
          },
          [_vm._v(" " + _vm._s(_vm.currentText) + " ")]
        )
      ]),
      _c(
        "generic-modal",
        {
          ref: "genericModal",
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function() {
                return undefined
              },
              proxy: true
            }
          ])
        },
        [[_vm._v(" " + _vm._s(_vm.text) + " ")]],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }