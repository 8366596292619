import { ApiResponse, CostCenterEditorDependencies, CostCenterManagementDto, PaginationResult } from '@/types'
import { BaseHttpService } from './base-http-service'

class CostCenterService extends BaseHttpService {
  public async getEditorDependencies(projectId?: number): Promise<CostCenterEditorDependencies> {
    const params = new URLSearchParams()
    if (projectId) {
      params.set('project_id', projectId.toString())
      params.set('project_based', 'true')
    }
    const uri = `/api/editor-data/cost-centers?${params.toString()}`
    const response = await this.doApiGet<CostCenterEditorDependencies>(uri)
    if (response.success) {
      return response.response!
    } else {
      this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
    }
  }

  public async getCostCenterPage(projectId: number, orderBy: string, pageNum = 1, pageSize?: number): Promise<PaginationResult<CostCenterManagementDto>> {
    const params = new URLSearchParams()
    params.set('page', pageNum.toString())
    params.set('order_by', orderBy)
    params.set('by_rep', 'true')
    params.set('include_wbs', 'true')
    params.set('include_reps', 'true')
    if (pageSize) {
      params.set('page_size', pageSize.toString())
    }
    const model = 'project-cost-centers'
    const uri = `/api/projects/${projectId}/${model}?${params.toString()}`
    const response = await this.doApiGet<PaginationResult<CostCenterManagementDto>>(uri)

    if (response.success) {
      return response.response!
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }

  public async saveCostCenterManagementRow(projectId: number, row: CostCenterManagementDto): Promise<void> {
    const uri = `/api/projects/${projectId}/project-cost-centers/${row.id}`
    const response = await this.doApiPut(uri, JSON.stringify(row))
    if (response.success) {
      return
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }

  public async deleteCostCenter(projectId: number, id: number): Promise<void> {
    const uri = `/api/projects/${projectId}/project-cost-centers/${id}`
    const response = await this.doApiDelete(uri)
    if (response.success) {
      return
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }

  public getEmptyCostCenterEditorDependencies(): CostCenterEditorDependencies {
    return {
      costCenterTypes: [],
      functionalAreas: [],
      costCenters: [],
    }
  }

  public async attachDetachResource(costCenterTypeId: number, resourceId: number, attaching: boolean, projectId?: number): Promise<boolean> {
    let uri: string
    if (projectId) {
      uri = `/api/projects/${projectId}/cost-center-types/${costCenterTypeId}/resources/${resourceId}`
    } else {
      uri = `/api/cost-center-types/${costCenterTypeId}/resources/${resourceId}`
    }
    let response: ApiResponse<boolean>
    if (attaching) {
      response = await this.doApiPost(uri)
    } else {
      response = await this.doApiDelete(uri)
    }
    if (response.success) {
      return response.response!
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
  }
}

export default new CostCenterService()
