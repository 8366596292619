var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "clin-management" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", [
          _vm._v(
            "CLIN Management for " +
              _vm._s(
                _vm.adminState.activeProject
                  ? _vm.adminState.activeProject.projectName
                  : "(No Project)"
              )
          )
        ]),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.numEditing > 0
              },
              on: {
                click: function($event) {
                  return _vm.refreshData(_vm.page)
                }
              }
            },
            [
              !_vm.loading
                ? _c("i", { staticClass: "bi-arrow-clockwise" })
                : _c(
                    "div",
                    {
                      staticClass: "spinner-border spinner-border-sm",
                      attrs: { role: "status" }
                    },
                    [
                      _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Loading...")
                      ])
                    ]
                  ),
              _c("span", { staticClass: "ms-1" }, [_vm._v("Refresh")])
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary ms-1",
              attrs: {
                type: "button",
                disabled: _vm.isCreating || _vm.numEditing > 0
              },
              on: { click: _vm.onAddNew }
            },
            [_c("i", { staticClass: "bi-plus-lg me-1" }), _vm._v("Create ")]
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          height: "75vh",
          "overflow-y": "scroll",
          "overflow-x": "hidden"
        }
      },
      [
        _c(
          "section",
          { staticClass: "editable-row-container" },
          [
            _c("editable-row-header", [
              _c("div", { staticClass: "fw-bold col-1" }, [_vm._v("CLIN")]),
              _c("div", { staticClass: "fw-bold col-3" }, [
                _vm._v("Description")
              ]),
              _c("div", { staticClass: "fw-bold col-2" }, [_vm._v("Type")]),
              _c("div", { staticClass: "fw-bold col-6" }, [
                _vm._v("Option Periods")
              ])
            ]),
            _c(
              "transition-group",
              { attrs: { name: "list" } },
              _vm._l(_vm.paginationResult.data, function(row) {
                return _c("editable-row", {
                  key: row.id + "-" + row.updatedAt,
                  attrs: { data: row, editOnStart: row.isNew },
                  on: {
                    save: _vm.onSave,
                    cancel: _vm.onCancel,
                    delete: _vm.onDelete,
                    modeChange: _vm.onModeChange
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            !_vm.loading
                              ? [
                                  _c("div", { staticClass: "col-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group has-validation pe-1"
                                      },
                                      [
                                        !slotProps.isEditing
                                          ? _c(
                                              "span",
                                              { staticClass: "prop-display" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    slotProps.data
                                                      .contractLineItemNumber
                                                  )
                                                )
                                              ]
                                            )
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    slotProps.data
                                                      .contractLineItemNumber,
                                                  expression:
                                                    "slotProps.data.contractLineItemNumber"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                type: "text",
                                                required: ""
                                              },
                                              domProps: {
                                                value:
                                                  slotProps.data
                                                    .contractLineItemNumber
                                              },
                                              on: {
                                                change: function($event) {
                                                  return slotProps.setDirty()
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    slotProps.data,
                                                    "contractLineItemNumber",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-3" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group has-validation pe-1"
                                      },
                                      [
                                        !slotProps.isEditing
                                          ? _c(
                                              "span",
                                              { staticClass: "prop-display" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    slotProps.data.description
                                                  )
                                                )
                                              ]
                                            )
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    slotProps.data.description,
                                                  expression:
                                                    "slotProps.data.description"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                type: "text",
                                                required: ""
                                              },
                                              domProps: {
                                                value:
                                                  slotProps.data.description
                                              },
                                              on: {
                                                change: function($event) {
                                                  return slotProps.setDirty()
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    slotProps.data,
                                                    "description",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group has-validation pe-1"
                                      },
                                      [
                                        !slotProps.isEditing
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "prop-display normalized-value"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    slotProps.data
                                                      .contractLineItemNumberTypeName
                                                  )
                                                )
                                              ]
                                            )
                                          : _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      slotProps.data
                                                        .contractLineItemNumberTypeId,
                                                    expression:
                                                      "slotProps.data.contractLineItemNumberTypeId"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-select form-select-sm",
                                                attrs: {
                                                  "aria-label": "CLIN Types",
                                                  required: ""
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        slotProps.data,
                                                        "contractLineItemNumberTypeId",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function($event) {
                                                      return slotProps.setDirty()
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("Select a Type")]
                                                ),
                                                _vm._l(
                                                  _vm.editorDeps.clinTypes,
                                                  function(opt) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: opt.id,
                                                        domProps: {
                                                          value: opt.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(opt.clinType) +
                                                            " (" +
                                                            _vm._s(
                                                              opt.acronym
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            ),
                                        _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-6 d-flex align-items-center"
                                    },
                                    _vm._l(
                                      slotProps.data.optionPeriods,
                                      function(op) {
                                        return _c(
                                          "div",
                                          {
                                            key: op.id + "-" + op.enabled,
                                            staticClass:
                                              "form-check form-check-inline"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: op.enabled,
                                                  expression: "op.enabled"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  slotProps.data.id +
                                                  "-op-" +
                                                  op.id,
                                                disabled: !slotProps.isEditing
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  op.enabled
                                                )
                                                  ? _vm._i(op.enabled, null) >
                                                    -1
                                                  : op.enabled
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    var $$a = op.enabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            op,
                                                            "enabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            op,
                                                            "enabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        op,
                                                        "enabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function($event) {
                                                    return _vm.rowState.setIsDirty(
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for:
                                                    slotProps.data.id +
                                                    "-role-" +
                                                    op.id
                                                }
                                              },
                                              [_vm._v(_vm._s(op.name))]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              : [
                                  _c("span", {
                                    staticClass: "placeholder col-1"
                                  }),
                                  _c("span", {
                                    staticClass: "placeholder col-3 mx-1"
                                  }),
                                  _c("span", {
                                    staticClass: "placeholder col-2 mx-1"
                                  }),
                                  _c("span", {
                                    staticClass: "placeholder col-6"
                                  })
                                ]
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            )
          ],
          1
        )
      ]
    ),
    _vm.paginationResult.meta.total > 0
      ? _c(
          "nav",
          {
            staticClass: "mt-2 d-flex align-items-center flex-column",
            attrs: { "aria-label": "Data pagination" }
          },
          [
            _c(
              "ul",
              {
                staticClass: "pagination justify-content-center mb-0",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.paginationResult.meta.currentPage === 1 ||
                          _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "First Page" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-double-left" })]
                    )
                  ]
                ),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.paginationResult.meta.currentPage === 1 ||
                          _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Previous" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page - 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-left" })]
                    )
                  ]
                ),
                _vm._l(_vm.prevPages, function(pg) {
                  return _c(
                    "li",
                    {
                      key: pg,
                      class: [
                        "page-item",
                        {
                          active: pg === _vm.paginationResult.meta.currentPage,
                          disabled: _vm.loading
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              return _vm.refreshData(pg)
                            }
                          }
                        },
                        [_vm._v(_vm._s(pg))]
                      )
                    ]
                  )
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy.number",
                      value: _vm.page,
                      expression: "page",
                      modifiers: { lazy: true, number: true }
                    }
                  ],
                  staticClass: "form-control form-control-sm mx-1 current-page",
                  attrs: {
                    type: "number",
                    min: "1",
                    max: _vm.paginationResult.meta.totalPages
                  },
                  domProps: { value: _vm.page },
                  on: {
                    change: [
                      function($event) {
                        _vm.page = _vm._n($event.target.value)
                      },
                      _vm.onPageChange
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._l(_vm.nextPages, function(pg) {
                  return _c(
                    "li",
                    {
                      key: pg,
                      class: [
                        "page-item",
                        {
                          active: pg === _vm.paginationResult.meta.currentPage,
                          disabled: _vm.loading
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              return _vm.refreshData(pg)
                            }
                          }
                        },
                        [_vm._v(_vm._s(pg))]
                      )
                    ]
                  )
                }),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.paginationResult.meta.currentPage ===
                          _vm.paginationResult.meta.lastPage
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Next" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page + 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-right" })]
                    )
                  ]
                ),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.paginationResult.meta.currentPage ===
                            _vm.paginationResult.meta.lastPage || _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Last Page" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(
                              _vm.paginationResult.meta.lastPage
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-double-right" })]
                    )
                  ]
                )
              ],
              2
            ),
            _c("div", { staticClass: "total-pages" }, [
              _vm._v(
                _vm._s(_vm.paginationResult.meta.totalPages) +
                  " " +
                  _vm._s(
                    _vm.paginationResult.meta.totalPages === 1
                      ? "page"
                      : "pages"
                  )
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }