










































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import EditableRow from '@/components/EditableRow.vue'
import EditableRowHeader from '@/components/EditableRowHeader.vue'
import AdminState from '@/store/modules/admin'
import { getModule } from 'vuex-module-decorators'
import ToastState from '@/store/modules/toast'
import { ClinManagementDto, ClinManagementEditorDependencies, CrudSaveEvent, PaginationResult } from '@/types'
import clinService from '@/services/clin-service'
import RowState from '@/store/modules/row'

@Component({
  components: {
    EditableRow,
    EditableRowHeader,
  }
})
export default class ClinManagement extends Vue {
  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)
  rowState: RowState = getModule(RowState)
  paginationResult: PaginationResult<ClinManagementDto> = clinService.getEmptyPaginationResult<ClinManagementDto>()
  loading = false
  page = 1
  editorDeps: ClinManagementEditorDependencies = clinService.getEmptyClinManagementEditorDependencies()
  numEditing = 0

  get isCreating(): boolean {
    return this.paginationResult.data.length > 0 && this.paginationResult.data[0].id === 0
  }

  async mounted(): Promise<void> {
    await this.refreshDependencies()
    await this.refreshData()
  }

  @Watch('adminState.activeProject')
  public async onProjectChange(): Promise<void> {
    await this.refreshDependencies()
    await this.refreshData()
  }

  public async refreshDependencies(): Promise<void> {
    try {
      this.loading = true
      this.editorDeps = await clinService.getEditorDependencies(this.adminState.activeProjectId)
    } catch (err: any) {
      console.error(err)
      this.toastState.showError('Error loading option periods: ' + err.message || '')
    } finally {
      this.loading = false
    }
  }

  public async refreshData(pageNum?: number): Promise<void> {
    try {
      if (pageNum && pageNum !== this.page) {
        this.page = pageNum
        this.$router.push({ path: `clins`, query: { page: this.page.toString() }})
      }
      this.loading = true
      this.paginationResult = await clinService.getClinPage('contractLineItemNumber', this.adminState.activeProjectId, this.page, 100)
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error loading records: ${err.message}`)
    } finally {
      this.loading = false
    }
  }

  public onAddNew(): void {
    const dto = clinService.getEmptyClinManagementDto()
    dto.isNew = true
    dto.projectId = this.adminState.activeProjectId
    dto.optionPeriods = this.editorDeps.optionPeriods.map(op => { return { id: op.id, name: op.optionPeriod, enabled: false }})
    this.paginationResult.data.unshift(dto)
    this.rowState.setCanEdit(false)
  }

  public async onSave(event: CrudSaveEvent): Promise<void> {
    const dto = event.data as ClinManagementDto
    try {
      await clinService.saveClinManagementRow(dto)
      event.component.actionComplete('Save')
      await this.refreshData()
      this.rowState.setIsDirty(false)
    } catch (err: any) {
      event.component.actionComplete('Save', err.message || '')
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onCancel(event: any): void {
    if (event.isNew) {
      this.paginationResult.data.shift()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onDelete(event: any): Promise<void> {
    try {
      await clinService.deleteClin(event.data.projectId, event.data.id)
      event.component.actionComplete('Delete')
      await this.refreshData()
    } catch (err: any) {
      event.component.actionComplete('Delete', err.message || '')
    }
  }

  get prevPages(): number[] {
    const start = Math.max(1, this.page - 5)
    const end = Math.max(1, this.page - 1)
    const diff = end - start
    console.log('start', start, 'end', end, 'diff', diff)
    return this.page === start ? [] : Array(diff + 1).fill(0).map((_, index) => start + index)
  }

  get nextPages(): number[] {
    const start = Math.min(this.page + 1, this.paginationResult.meta.totalPages)
    const end = Math.min(this.page + 5, this.paginationResult.meta.totalPages)
    console.log('np start', start, 'end', end, 'page', this.page, 'total', this.paginationResult.meta)
    return this.page === end ? [] : Array(end - start + 1).fill(0).map((_, index) => start + index)
  }

  clampPageNumber(isMounting = false): void {
    if (this.page < 1) {
      this.page = 1
    } else if (!isMounting && this.page > this.paginationResult.meta.totalPages) {
      this.page = this.paginationResult.meta.totalPages || 1
    }
    console.log('clamped to page', this.page)
  }

  async onPageChange(): Promise<void> {
    this.clampPageNumber()
    await this.refreshData(this.page)
  }

  public async onModeChange(mode: 'edit' | 'normal'): Promise<void> {
    if (mode === 'edit') {
      this.numEditing += 1
      this.rowState.setCanEdit(false)
    } else {
      this.numEditing -= 1
      this.rowState.setCanEdit(true)
    }
  }
}
