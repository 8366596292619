var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageMeta.total > 0
    ? _c(
        "nav",
        {
          staticClass: "mt-2 d-flex align-items-center flex-column",
          attrs: { "aria-label": "Data pagination" }
        },
        [
          _c(
            "ul",
            {
              staticClass: "pagination justify-content-center mb-0",
              staticStyle: { cursor: "pointer" }
            },
            [
              _c(
                "li",
                {
                  class: [
                    "page-item",
                    { disabled: _vm.pageMeta.currentPage === 1 || _vm.loading }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { "aria-label": "First Page" },
                      on: {
                        click: function($event) {
                          return _vm.setPageNumber(1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "bi-chevron-double-left" })]
                  )
                ]
              ),
              _c(
                "li",
                {
                  class: [
                    "page-item",
                    { disabled: _vm.pageMeta.currentPage === 1 || _vm.loading }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { "aria-label": "Previous" },
                      on: {
                        click: function($event) {
                          return _vm.setPageNumber(_vm.page - 1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "bi-chevron-left" })]
                  )
                ]
              ),
              _vm._l(_vm.prevPages, function(pg) {
                return _c(
                  "li",
                  {
                    key: pg,
                    class: [
                      "page-item",
                      {
                        active: pg === _vm.pageMeta.currentPage,
                        disabled: _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function($event) {
                            return _vm.setPageNumber(pg)
                          }
                        }
                      },
                      [_vm._v(_vm._s(pg))]
                    )
                  ]
                )
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy.number",
                    value: _vm.page,
                    expression: "page",
                    modifiers: { lazy: true, number: true }
                  }
                ],
                staticClass: "form-control form-control-sm mx-1 current-page",
                attrs: {
                  type: "number",
                  min: "1",
                  max: _vm.pageMeta.totalPages
                },
                domProps: { value: _vm.page },
                on: {
                  change: [
                    function($event) {
                      _vm.page = _vm._n($event.target.value)
                    },
                    _vm.onPageChange
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._l(_vm.nextPages, function(pg) {
                return _c(
                  "li",
                  {
                    key: pg,
                    class: [
                      "page-item",
                      {
                        active: pg === _vm.pageMeta.currentPage,
                        disabled: _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function($event) {
                            return _vm.setPageNumber(pg)
                          }
                        }
                      },
                      [_vm._v(_vm._s(pg))]
                    )
                  ]
                )
              }),
              _c(
                "li",
                {
                  class: [
                    "page-item",
                    {
                      disabled:
                        _vm.pageMeta.currentPage === _vm.pageMeta.lastPage
                    }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { "aria-label": "Next" },
                      on: {
                        click: function($event) {
                          return _vm.setPageNumber(_vm.page + 1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "bi-chevron-right" })]
                  )
                ]
              ),
              _c(
                "li",
                {
                  class: [
                    "page-item",
                    {
                      disabled:
                        _vm.pageMeta.currentPage === _vm.pageMeta.lastPage ||
                        _vm.loading
                    }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { "aria-label": "Last Page" },
                      on: {
                        click: function($event) {
                          return _vm.setPageNumber(_vm.pageMeta.lastPage)
                        }
                      }
                    },
                    [_c("i", { staticClass: "bi-chevron-double-right" })]
                  )
                ]
              )
            ],
            2
          ),
          _c("div", { staticClass: "total-pages" }, [
            _vm._v(
              _vm._s(_vm.pageMeta.totalPages) +
                " " +
                _vm._s(_vm.pageMeta.totalPages === 1 ? "page" : "pages")
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }