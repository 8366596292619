



























import { Component } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import ProjectLevelAdminEditor from '../projects/ProjectLevelAdminEditor'

@Component({
  components: {
    EditableList
  }
})
export default class FunctionalAreas extends ProjectLevelAdminEditor {

  get baseDataModelUri(): string {
    return 'functional-areas'
  }
}
